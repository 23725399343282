import {Alert, Button, Form, Input, Select} from 'antd';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {apiCreateArise} from '../../API/arise';
import {apiGetListProject} from '../../API/project';
import {NOTIFICATION_TYPE} from '../../Contant/global';
import {openNotification} from '../../Helper/notification';
import {getMessageErrors, optionValidate} from '../../Helper/vailate';

function AriseCreate(props) {
	const [form] = Form.useForm();
	const [errors, setErrors] = useState([]);
	const [projects, setProjects] = useState([]);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();
	const onFinish = () => {
		console.log(form.getFieldsValue());
		fetchAriseCreate();
	};
	const fetchProjectList = () => {
		const params = {
			currentPage: 1,
			perPage: 1000,
			status: 1,
		};
		apiGetListProject(params)
			.then((r) => {
				console.log(r);
				setProjects(r);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const fetchAriseCreate = () => {
		setLoading(true);
		const all = form.getFieldsValue();

		apiCreateArise(all)
			.then((response) => {
				openNotification(NOTIFICATION_TYPE.success, 'Tạo mới thành công !');
			})
			.then((response) => {
				navigate('/arise');
			})
			.catch((err) => {
				openNotification(NOTIFICATION_TYPE.error, 'Tạo mới thất bại !');
				console.log(err);
				setLoading(false);
			});
	};
	useEffect(() => {
		fetchProjectList();
	}, []);
	return (
		<div className='px-8  ' style={{width: 390}}>
			<Form
				onFinish={onFinish}
				onFinishFailed={() => {
					setErrors(getMessageErrors(form.getFieldsError()));
				}}
				form={form}
				layout='vertical'>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Dự án
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('projectId') ? '' : 'error'}
					rules={optionValidate(true, null, null, null, null, 'Công trình')}
					name={'projectId'}>
					<Select
						placeholder='Công trình'
						className='w-full'
						options={projects?.data?.map((item) => ({
							value: item?.id,
							label: item?.name,
						}))}
					/>
				</Form.Item>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Tiêu đề
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('name') ? '' : 'error'}
					rules={optionValidate(true, null, null, 5, 55, 'Tiêu đề')}
					label='Họ Tên'
					name={'name'}>
					<Input placeholder='Vui lòng nhập' />
				</Form.Item>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Mô tả
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('note') ? '' : 'error'}
					rules={optionValidate(true, null, null, 1, 2000, 'Mô tả')}
					label='Họ Tên'
					name={'note'}>
					<Input.TextArea rows={5} placeholder='Vui lòng nhập' />
				</Form.Item>
				{errors.length > 0 && (
					<Alert
						className='rounded mt-5'
						message={
							<div className='flex flex-col text-gray-500' style={{fontSize: 13}}>
								{errors?.map((o, i) => (
									<div key={i}>{o}</div>
								))}
							</div>
						}
						type='warning'
					/>
				)}

				<div className='flex justify-between  mt-5'>
					<Button
						loading={loading}
						className='w-full'
						type='primary'
						htmlType='submit'>
						Tạo mới
					</Button>
				</div>
			</Form>
		</div>
	);
}

AriseCreate.propTypes = {};

export default AriseCreate;
