import {axios} from '../Conf/axios';

export const apiGetListEquipment = (params) =>
	axios.get('/equipment/list', {params});

export const apiCreateEquipment = (data) =>
	axios.post(`/equipment/create`, data);

export const apiUpdateEquipment = (id, data) =>
	axios.put(`/equipment/${id}/update`, data);

export const apiRemoveEquipment = (id) =>
	axios.delete(`/equipment/${id}/remove`);

export const apiEquipmentDetails = (id) =>
	axios.get(`/equipment/${id}/details`);
