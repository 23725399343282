import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {DownOutlined, SmileOutlined} from '@ant-design/icons';
import {Dropdown, Modal} from 'antd';
import {Button, Input, Pagination, Space, Table, Tag} from 'antd';
import {Link, useNavigate} from 'react-router-dom';

import {AiOutlineHolder} from 'react-icons/ai';
import {apiGetListEmployee, apiRemoveEmployee} from '../../API/employee';
import moment from 'moment';
import EmployeeDetail from './EmployeeDetails';
import {openNotification} from '../../Helper/notification';
import {NOTIFICATION_TYPE} from '../../Contant/global';
import {useMitt} from 'react-mitt';
import {GrPowerReset} from 'react-icons/gr';
import {ExclamationCircleOutlined} from '@ant-design/icons';

const initialValues = {
	currentPage: 1,
	perPage: 10,
};

function Employee(props) {
	const navigate = useNavigate();
	const [employees, setEmployees] = useState([]);
	const [paramsList, setParamsList] = useState(initialValues);
	const {emitter} = useMitt();
	const [modal, contextHolder] = Modal.useModal();

	const items = (data) => [
		{
			key: '1',
			label: <EmployeeDetail data={data} />,
		},
		{
			key: '2',
			label: (
				<Link to={`/employee/${data?.id}/update`} rel='noopener noreferrer'>
					Cập nhật
				</Link>
			),
		},

		{
			key: '4',
			danger: true,

			label: (
				<div aria-hidden onClick={() => confirm(data?.id)} style={{width: 200}}>
					Xóa bỏ
				</div>
			),
		},
	];
	const columns = [
		{
			title: '#',

			render: (text, record, index) => (
				<span>
					{index + 1 + (paramsList?.currentPage - 1) * paramsList?.perPage}
					{/* <Tag bordered={false} color='warning' className='rounded-xl'>
						new
					</Tag> */}
				</span>
			),
		},

		{
			title: 'Họ tên',
			dataIndex: 'fullName',
			key: 'fullName',
			render: (_, record) => (
				<div className='flex flex-col'>
					<span>{record?.fullName}</span>
					<small className='text-gray-400 '>{record?.idCard}</small>
				</div>
			),
		},

		{
			title: 'Số điện thoại',
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: 'Năm sinh',
			dataIndex: 'birthday',
			key: 'birthday',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Giới tính',
			dataIndex: 'gender',
			key: 'gender',
			render: (text) => {
				return text == 0 ? 'Nam' : 'Nữ';
			},
		},
		{
			title: 'Lương',

			render: (_, record) => (
				<div className='flex flex-col'>
					<span>{new Intl.NumberFormat().format(record?.salary?.wage)}</span>
					<small className='text-gray-400'>
						TC {new Intl.NumberFormat().format(record?.salary?.wageOvertime)}
					</small>
				</div>
			),
		},
		{
			title: 'Địa chỉ',
			dataIndex: 'address',
			key: 'address',
			render: (text) => <span>{text}</span>,
		},

		{
			title: 'Trạng thái',
			key: 'status',
			dataIndex: 'status',
			className: 'text-center',
			render: (text) => {
				return text == 0 ? (
					<div
						className='rounded-sm text-center text-green-500 '
						style={{width: 120}}>
						Hoạt động
					</div>
				) : (
					<div
						className='rounded-sm text-center text-rose-500  '
						style={{width: 120}}>
						Bị khóa
					</div>
				);
			},
		},
		{
			title: 'Ngày tạo',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (_, record) => (
				<>
					<div>{moment(record?.createdAt).format('hh:mm DD/MM/YYYY')}</div>
					<small className='text-gray-300'>
						{moment(record?.updatedAt).format('hh:mm DD/MM/YYYY')}
					</small>
				</>
			),
		},
		{
			title: 'Thao tác',
			key: 'action',

			render: (_, record) => (
				<Dropdown
					className='w-48'
					menu={{
						items: items(record),
					}}>
					<a
						className='text-sky-600 '
						href='#sda'
						onClick={(e) => e.preventDefault()}>
						<Space>
							<AiOutlineHolder />
						</Space>
					</a>
				</Dropdown>
			),
		},
	];
	const confirm = (id) => {
		modal.confirm({
			title: 'Xác nhận',
			icon: <ExclamationCircleOutlined />,
			content: 'Bạn chắc chắn muốn xóa !',
			okText: 'Đồng ý',
			onOk: () => {
				console.log('sadfads');
				fetchRemoveEmployee(id);
			},
		});
	};
	const fetchEmployeeList = (params) => {
		emitter.emit('pendingOn');
		apiGetListEmployee(params)
			.then((r) => {
				console.log(r);
				setEmployees(r);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				emitter.emit('pendingOff');
			});
	};

	const fetchRemoveEmployee = (id) => {
		apiRemoveEmployee(id)
			.then((response) => {
				openNotification(NOTIFICATION_TYPE.success, 'Xóa thành công !');
			})
			.then((r) => {
				fetchEmployeeList(paramsList);
			})
			.catch((err) => {
				openNotification(NOTIFICATION_TYPE.error, 'Xóa thất bại !');
				console.log(err);
			});
	};
	useEffect(() => {
		fetchEmployeeList(paramsList);
	}, []);
	return (
		<div className='py-10'>
			{contextHolder}
			<div className='flex justify-between mb-7 px-5'>
				<div>
					<Button
						type='primary'
						onClick={() => {
							navigate('/employee/create');
						}}>
						Tạo mới{' '}
					</Button>
				</div>
				<div className='flex items-center gap-2'>
					<Input
						placeholder='Nhập Họ Tên'
						onChange={(e) => {
							setParamsList({...paramsList, fullName: e.target.value});
						}}
						style={{width: 200}}
					/>
					<Button
						onClick={() => {
							setParamsList(initialValues);
						}}
						className=''
						type='default'>
						<GrPowerReset />
					</Button>
					<Button
						type='primary'
						onClick={() => {
							fetchEmployeeList(paramsList);
						}}>
						Tìm kiếm
					</Button>
				</div>
			</div>
			<Table columns={columns} dataSource={employees?.data} pagination={false} />
			<div className='flex justify-end mt-5'>
				<Pagination
					pageSize={paramsList.perPage}
					current={paramsList?.currentPage}
					total={employees?.meta?.total}
					onChange={(v) => {
						setParamsList({...initialValues, currentPage: v});
						fetchEmployeeList({...initialValues, currentPage: v});
					}}
				/>
			</div>
		</div>
	);
}

Employee.propTypes = {};

export default Employee;
