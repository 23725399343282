import {createSlice} from '@reduxjs/toolkit';
import {signupApi} from '../apis/auth';
import {getProfileAction, loginAction, signupAction} from '../actions/auth';
import {history} from '../../Routes/CustomerRouter';

const initialState = {
	isLogin: false, //bolean
	id: null,
	accountId: null,
	username: null,
	type: null,
	role: null, //int
	error: {
		logging: false,
	},
	loading: true,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		evtLogout: (state) => {
			state.error.logging = false;
			state.isLogin = false;
			state.id = null;
			state.accountId = null;
			state.username = null;
			state.type = null;
			localStorage.clear();
			history.push('/sign-in');
		},

		statusLoggingFalse: (state) => {
			state.error.logging = false;
		},
	},
	extraReducers: (builder) => {
		//state, action
		builder.addCase(loginAction.fulfilled, (state, action) => {
			const user = action.payload;
			localStorage.setItem('accessToken', user.accessToken);
			state.isLogin = true;
			state.id = user.id;
			state.accountId = user.accountId;
			state.username = user.username;
			state.type = user.type;
			history.push('/');
		});

		builder.addCase(loginAction.rejected, (state) => {
			state.error.logging = true;
		});

		//state, action
		builder.addCase(signupAction.fulfilled, (state, action) => {
			// const user = action.payload;
			// localStorage.setItem('accessToken', user.accessToken);
			// state.isLogin = true;
			// state.id = user.id;
			// state.accountId = user.accountId;
			// state.username = user.username;
			// state.type = user.type;
			// history.push('/');
		});

		builder.addCase(signupAction.rejected, (state) => {
			state.error.logging = true;
		});

		builder.addCase(getProfileAction.rejected, (state) => {
			state.loading = false;
			history.push('/sign-in');
		});
	},
});

// Action creators are generated for each case reducer function
export const {evtLogout, statusLoggingFalse} = authSlice.actions;

export default authSlice.reducer;
