import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Checkbox, Form, Input} from 'antd';
import {SlLogin} from 'react-icons/sl';
import {useDispatch} from 'react-redux';
import {loginAction, signupAction} from '../../Store/actions/auth';
import {useNavigate} from 'react-router-dom';
import {
	getMessageErrors,
	optionValidate,
	validateConfirmPassword,
	validateEmail,
	validatePassword,
} from '../../Helper/vailate';

function SignUp(props) {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [errors, setErrors] = useState([]);
	const [fail, setFail] = useState(false);
	const [verify, setVerify] = useState(false);
	const [loading, setLoading] = useState(false);

	const onFinish = () => {
		setFail(false);
		setVerify(false);
		setErrors([]);
		setLoading(true);

		const data = form.getFieldsValue();
		const member = {
			username: data.username,
			password: data.password,
			email: data?.email,
		};
		delete data.username;
		delete data.password;
		const payload = {
			account: data,
			member,
		};
		const type = dispatch(signupAction(payload));
		type
			.then((r) => {
				console.log('r', r);
				if (r?.error != null) {
					setFail(true);
				}
				if (r?.type == 'SIGNUP_ACTION/fulfilled') {
					setVerify(true);
				}
				form.resetFields();
			})

			.catch((e) => {
				console.log('e', e);
			})
			.finally((R) => {
				setLoading(false);
			});
	};
	return (
		<div
			id='auth'
			className='flex bg-gray-100 justify-end items-center h-screen w-screen'>
			<div
				style={{width: 370}}
				className='bg-white border-l border-orange-600 h-screen  shadow  '>
				<div className='p-10'>
					<div className='font-bold  text-2xl text-center  mt-5 '>
						V
						<span className='font-bold italic text-3xl text-center  text-orange-500   mt-5 '>
							NN
						</span>
						IX
					</div>
					<div className='font-thin text-sm text-center mb-2 italic text-gray-500'>
						Software V1.0.1
					</div>
					<div className='mb-4 text-center  text-sm text-gray-400'>
						Đăng ký hệ thống quản lý{' '}
					</div>
					{/* <div className='uppercase mb-6 text-xs text-end text-gray-400'>
						Tư Vấn - Thiết Kế - Xây Dựng
					</div> */}
					<Form
						form={form}
						onFinish={onFinish}
						onFinishFailed={() => {
							setFail(false);
							setErrors(getMessageErrors(form.getFieldsError()));
						}}
						className='mt-7'
						name='basic'
						layout='vertical'
						autoComplete='off'>
						<label
							htmlFor='company'
							className='block mb-2   text-gray-900 dark:text-white mt-2'>
							Tổ chức
						</label>
						<Form.Item
							noStyle={true}
							validateStatus={form.getFieldError('company') ? '' : 'error'}
							rules={optionValidate(true, null, null, 5, 55, 'tổ chức')}
							label='Tổ chức'
							name='company'>
							<Input placeholder='Tên tổ chức' />
						</Form.Item>
						<label
							htmlFor='phone'
							className='block mb-2   text-gray-900 dark:text-white mt-2'>
							Liên hệ
						</label>
						<Form.Item
							noStyle={true}
							validateStatus={form.getFieldError('phone') ? '' : 'error'}
							rules={optionValidate(true, null, null, 10, 11, 'liên hệ')}
							label='Liên hệ'
							name='phone'>
							<Input placeholder='0325.456.789' />
						</Form.Item>
						<label
							htmlFor='email'
							className='block mb-2   text-gray-900 dark:text-white mt-2'>
							Mail
						</label>
						<Form.Item
							noStyle={true}
							validateStatus={form.getFieldError('email') ? '' : 'error'}
							rules={[
								...optionValidate(true, null, null, null, null, 'email'),
								{validator: validateEmail},
							]}
							label='Email'
							name='email'>
							<Input placeholder='admin@company.com' />
						</Form.Item>
						<label
							htmlFor='address'
							className='block mb-2   text-gray-900 dark:text-white mt-2'>
							Địa chỉ
						</label>
						<Form.Item
							noStyle={true}
							validateStatus={form.getFieldError('address') ? '' : 'error'}
							rules={optionValidate(true, null, null, 5, 100, 'địa chỉ')}
							label='Địa chỉ'
							name='address'>
							<Input placeholder='Biên Hòa - Đồng Nai' />
						</Form.Item>
						<label
							htmlFor='username'
							className='block mb-2   text-gray-900 dark:text-white mt-2'>
							Tài khoản
						</label>
						<Form.Item
							noStyle={true}
							validateStatus={form.getFieldError('username') ? '' : 'error'}
							rules={optionValidate(true, null, null, 5, 55, 'tài khoản')}
							label='Tài khoản'
							name='username'>
							<Input placeholder='admin123' />
						</Form.Item>
						<label
							htmlFor='password'
							className='block mb-2   text-gray-900 dark:text-white mt-2'>
							Mật khẩu
						</label>
						<Form.Item
							noStyle={true}
							validateStatus={form.getFieldError('password') ? '' : 'error'}
							rules={[{validator: validatePassword}]}
							label='Mật khẩu'
							name='password'>
							<Input className='txtPassword' placeholder='*********' />
						</Form.Item>

						{errors.length > 0 && (
							<Alert
								className='rounded mt-5'
								message={
									<div className='flex flex-col text-gray-500' style={{fontSize: 13}}>
										{errors?.map((o, i) => (
											<div key={i}>{o}</div>
										))}
									</div>
								}
								type='warning'
							/>
						)}
						{fail && (
							<Alert
								className='rounded mt-5'
								message={
									<div className='flex flex-col text-gray-500' style={{fontSize: 13}}>
										{'Đăng ký thất bại. Vui lòng thử lại.'}
									</div>
								}
								type='warning'
							/>
						)}
						{verify && (
							<Alert
								className='rounded mt-5'
								message={
									<div className='flex flex-col text-gray-500' style={{fontSize: 13}}>
										{'Đăng ký thành công. Đang đợi xác minh.'}
									</div>
								}
								type='success'
							/>
						)}
						<Form.Item>
							<Button
								loading={loading}
								icon={<SlLogin />}
								className='w-full mt-5 font-semibold'
								type='primary'
								htmlType='submit'>
								ĐĂNG KÝ
							</Button>
						</Form.Item>
						<div className='flex justify-between items-center'>
							<div>Bạn đã có tài khoản ?</div>
							<Button
								onClick={() => {
									navigate('/sign-in');
								}}
								type='link'>
								Đăng nhập
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}

SignUp.propTypes = {};

export default SignUp;
