import {axios} from '../Conf/axios';

export const apiGetListProject = (params) =>
	axios.get('/project/list', {params});

export const apiCreateProject = (data) => axios.post(`/project/create`, data);

export const apiUpdateProject = (id, data) =>
	axios.put(`/project/${id}/update`, data);

export const apiRemoveProject = (id) => axios.delete(`/project/${id}/remove`);

export const apiProjectDetails = (id) => axios.get(`/project/${id}/details`);
