import {axios} from '../Conf/axios';

export const apiGetListAccount = (params) =>
	axios.get('/member/list', {params});

export const apiCreateAccount = (data) => axios.post(`/member/create`, data);

export const apiUpdateAccount = (id, data) =>
	axios.put(`/member/${id}/update`, data);

export const apiRemoveAccount = (id) => axios.delete(`/member/${id}/remove`);

export const apiAccountDetails = (id) => axios.get(`/member/${id}/details`);

export const apiAccountMainDetails = (id) => axios.get(`/account/details`);

export const apiUpdateMainAccount = (id, data) =>
	axios.put(`/account/update`, data);

export const apiChangePasswordAccount = (id, data) =>
	axios.post(`/member/change-password`, data);
