import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import BaseInput from '../../Components/BaseInput';
import {
	apiAccountDetails,
	apiChangePasswordAccount,
	apiUpdateAccount,
} from '../../API/account';
// import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
	getMessageErrors,
	optionValidate,
	validateConfirmPassword,
	validatePassword,
} from '../../Helper/vailate';
import {Alert, Button, Divider, Form, Input} from 'antd';
import BaseSelectEdit from '../../Components/BaseSelectEdit';
import {openNotification} from '../../Helper/notification';
import {NOTIFICATION_TYPE} from '../../Contant/global';
import {useSelector} from 'react-redux';

function ChangePassword(props) {
	const [form] = Form.useForm();
	const {id} = useSelector((state) => state.auth);
	const [errors, setErrors] = useState([]);
	const [loading, setLoading] = useState(false);

	const onFinish = () => {
		const data = form.getFieldsValue();
		delete data?.rePassword;
		// fetchAccountCreate();
		apiChangePasswordAccount(id, data)
			.then((response) => {
				setLoading(true);
				openNotification(NOTIFICATION_TYPE.success, 'Thay đổi thành công !');
			})

			.catch((err) => {
				openNotification(NOTIFICATION_TYPE.error, 'Thay đổi thất bại !');
				console.log(err);
			})
			.finally((r) => {
				setLoading(false);
			});
	};
	return (
		<div className='px-10 pt-5 ' style={{width: 400}}>
			<Form
				onFinishFailed={() => {
					setErrors(getMessageErrors(form.getFieldsError()));
				}}
				onFinish={onFinish}
				form={form}
				layout='vertical'>
				<label
					htmlFor='username'
					className='block mb-2   text-gray-900 dark:text-white mt-2'>
					Mật khẩu củ
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('password') ? '' : 'error'}
					rules={[
						...optionValidate(true, null, null, null, null, 'Mật khẩu'),
						{validator: validatePassword},
					]}
					label='Mật khẩu củ'
					name={'oldPassword'}>
					<Input placeholder='********' className='txtPassword' />
				</Form.Item>
				<label
					htmlFor='username'
					className='block mb-2   text-gray-900 dark:text-white mt-2'>
					Mật khẩu mới
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('newPassword') ? '' : 'error'}
					rules={[
						...optionValidate(true, null, null, null, null, 'Mật khẩu mới'),
						{validator: validatePassword},
					]}
					label='Mật khẩu mới'
					name={'newPassword'}>
					<Input placeholder='********' className='txtPassword' />
				</Form.Item>
				<label
					htmlFor='username'
					className='block mb-2   text-gray-900 dark:text-white mt-2'>
					Nhập lại
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('rePassword') ? '' : 'error'}
					rules={[
						...optionValidate(true, null, null, null, null, 'Mật khẩu'),
						validateConfirmPassword,
					]}
					label='Nhập lại'
					name={'rePassword'}>
					<Input placeholder='********' className='txtPassword' />
				</Form.Item>
				{errors.length > 0 && (
					<Alert
						className='rounded mt-5'
						message={
							<div className='flex flex-col text-gray-500' style={{fontSize: 13}}>
								{errors?.map((o, i) => (
									<div key={i}>{o}</div>
								))}
							</div>
						}
						type='warning'
					/>
				)}
				<div className=' mt-5 '>
					<Button
						loading={loading}
						className='w-full'
						type='primary'
						htmlType='submit'>
						Xác nhận
					</Button>
				</div>
			</Form>
		</div>
	);
}

ChangePassword.propTypes = {};

export default ChangePassword;
