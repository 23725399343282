import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
	Alert,
	Button,
	Cascader,
	ColorPicker,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Radio,
	Select,
	Slider,
	Switch,
	TreeSelect,
} from 'antd';
import {apiCreateProject} from '../../API/project';
import {openNotification} from '../../Helper/notification';
import {NOTIFICATION_TYPE} from '../../Contant/global';
import {useNavigate} from 'react-router-dom';
import {getMessageErrors, optionValidate} from '../../Helper/vailate';

function ContructionCreate(props) {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [errors, setErrors] = useState([]);
	const [loading, setLoading] = useState(false);

	const onFinish = () => {
		fetchProjectCreate();
	};

	const fetchProjectCreate = () => {
		setLoading(true);
		const data = {
			...form.getFieldsValue(),
		};
		apiCreateProject(data)
			.then((response) => {
				openNotification(NOTIFICATION_TYPE.success, 'Tạo mới thành công !');
			})
			.then((response) => {
				navigate('/construction');
			})
			.catch((err) => {
				openNotification(NOTIFICATION_TYPE.error, 'Tạo mới thất bại !');
				console.log(err);
				setLoading(false);
			});
	};

	return (
		<div className='px-4   w-1/2'>
			<Form
				onFinishFailed={() => {
					setErrors(getMessageErrors(form.getFieldsError()));
				}}
				onFinish={onFinish}
				form={form}
				layout='vertical'>
				<div className='flex gap-7  w-full'>
					<div className=' p-6 rounded w-1/2'>
						<label
							htmlFor='password'
							className='block mb-4   text-gray-900 dark:text-white mt-3'>
							Tên dự án
						</label>
						<Form.Item
							noStyle={true}
							validateStatus={form.getFieldError('name') ? '' : 'error'}
							rules={optionValidate(true, null, null, 5, 25, 'Tên dự án')}
							label='Tên dự án'
							name={'name'}>
							<Input placeholder='Vui lòng nhập' />
						</Form.Item>
						<label
							htmlFor='password'
							className='block mb-4   text-gray-900 dark:text-white mt-3'>
							Chủ đầu tư
						</label>
						<Form.Item
							noStyle={true}
							validateStatus={form.getFieldError('fullName') ? '' : 'error'}
							rules={optionValidate(true, null, null, 5, 25, 'Chủ đầu tư')}
							label='Chủ đầu tư'
							name={'fullName'}>
							<Input placeholder='Vui lòng nhập' />
						</Form.Item>
						<label
							htmlFor='password'
							className='block mb-4   text-gray-900 dark:text-white mt-3'>
							Loại dự án
						</label>
						<Form.Item
							noStyle={true}
							validateStatus={form.getFieldError('type') ? '' : 'error'}
							rules={optionValidate(true, null, null, 5, 25, 'Loại dự án')}
							label='Loại dự án'
							name={'type'}>
							<Input placeholder='Vui lòng nhập' />
						</Form.Item>
						<label
							htmlFor='password'
							className='block mb-4   text-gray-900 dark:text-white mt-3'>
							Kinh phí
						</label>
						<Form.Item
							label='Kinh phí'
							name={'expense'}
							noStyle={true}
							validateStatus={form.getFieldError('expense') ? '' : 'error'}
							rules={optionValidate(
								true,
								1000000,
								100000000000,
								null,
								null,
								'Kinh phí'
							)}>
							<InputNumber placeholder='1.000.000.000' className='w-full' />
						</Form.Item>
						<label
							htmlFor='password'
							className='block mb-4   text-gray-900 dark:text-white mt-3'>
							Địa chỉ
						</label>
						<Form.Item
							noStyle={true}
							validateStatus={form.getFieldError('address') ? '' : 'error'}
							rules={optionValidate(true, null, null, 5, 100, 'địa chỉ')}
							label='Địa chỉ'
							name={'address'}>
							<Input placeholder='Tam Phước - Biên Hòa - Đồng Nai' />
						</Form.Item>
						{errors.length > 0 && (
							<Alert
								className='rounded mt-5'
								message={
									<div className='flex flex-col text-gray-500' style={{fontSize: 13}}>
										{errors?.map((o, i) => (
											<div key={i}>{o}</div>
										))}
									</div>
								}
								type='warning'
							/>
						)}
					</div>
				</div>
				<div className='w-1/2 px-6'>
					<Button
						loading={loading}
						className='w-full'
						htmlType='submit'
						type='primary'>
						Tạo mới
					</Button>
				</div>
			</Form>
		</div>
	);
}

ContructionCreate.propTypes = {};

export default ContructionCreate;
