import React, {useEffect} from 'react';
import {Badge, Calendar} from 'antd';
import PropTypes from 'prop-types';

const BaseCalendar = ({data}) => {
	const countWorkday = (list, type, date) => {
		return _.sumBy(list, (detail) => {
			if (detail.type == type && detail?.date == date) {
				return detail.time;
			}
			return 0;
		});
	};

	const getListData = (value) => {
		const newData = _.map(data, (item, index) => ({
			...item,
			date: moment(new Date(item?.date)).format('DD/MM/YYYY'),
		}));
		const temp1 = countWorkday(newData, 0, value) / 8;
		const temp2 = countWorkday(newData, 1, value);
		let newList = [];
		if (temp1 != 0) {
			newList = [
				...newList,
				{
					type: 'success',
					content: countWorkday(newData, 0, value) / 8 + ' (c)',
				},
			];
		}
		if (temp2 != 0) {
			newList = [
				...newList,
				{
					type: 'warning',
					content: countWorkday(newData, 1, value) + ' (h) ',
				},
			];
		}
		return newList;
	};

	const dateCellRender = (value) => {
		const listData = getListData(moment(new Date(value)).format('DD/MM/YYYY'));
		return (
			<ul className='events'>
				{listData.map((item) => (
					<li key={item.content}>
						<Badge status={item.type} text={item.content} />
					</li>
				))}
			</ul>
		);
	};
	const cellRender = (current, info) => {
		if (info.type === 'date') return dateCellRender(current);
		return info.originNode;
	};

	return <Calendar cellRender={cellRender} />;
};
BaseCalendar.propTypes = {
	data: PropTypes.object,
};

export default BaseCalendar;
