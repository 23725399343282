import React from 'react';
import PropTypes from 'prop-types';
import {Button, Result} from 'antd';
import {FrownOutlined} from '@ant-design/icons';
import {TbFaceIdError} from 'react-icons/tb';

export class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {hasError: false};
	}
	static getDerivedStateFromError(error) {
		return {hasError: true};
	}

	componentDidCatch(error, errorInfo) {
		console.log(error);
		console.log(errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className='w-screen h-screen flex justify-center items-center'>
					<Result
						className='pb-32'
						status='error'
						icon={<TbFaceIdError color='gray' size={100} />}
						title={
							<div className='text-2xl font-extrabold text-gray-500'>
								SYSTEM OPS !{' '}
							</div>
						}
						extra={
							<button
								onClick={() => {
									window.location.href = '/';
								}}
								size='large'
								className='px-4 py-2 rounded-sm text-blue-600 '>
								Go Back Home
							</button>
						}
					/>
					<div
						className='fixed bottom-0 right-0 text-sm text-gray-400'
						style={{fontSize: 8}}>
						{window.navigator.appVersion}
					</div>
				</div>
			);
		}
		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node,
	history: PropTypes.func,
};
