import {Badge, Col, Divider, Drawer, QRCode, Row} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {apiProjectDetails} from '../../API/project';
import {apiGetRevExpandProject} from '../../API/revExpandItem';
const DescriptionItem = ({title, content}) => (
	<div className='site-description-item-profile-wrapper'>
		<p className='site-description-item-profile-p-label'>{title}:</p>
		{content}
	</div>
);

DescriptionItem.propTypes = {
	title: PropTypes.string,
	content: PropTypes.any,
};
function ContructionDetails({data}) {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [revExpand, setRevExpand] = useState([]);

	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};
	const [projectDetails, setProjectDetails] = useState();

	const fetchProjectDetailById = (id) => {
		apiProjectDetails(id)
			.then((r) => {
				setProjectDetails(r);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(() => {
		console.log('data?.id', data);
		if (data?.id) {
			apiGetRevExpandProject(data?.id)
				.then((r) => {
					setRevExpand(r);
					console.log(_.filter(r, (item) => item?.type == 0));
				})
				.catch((e) => {
					console.log('e', e);
				});
			fetchProjectDetailById(data?.id);
		}
	}, [data?.id]);
	return (
		<>
			<button onClick={showDrawer}>Chi tiết</button>
			<Drawer
				width={340}
				placement='right'
				closable={false}
				onClose={onClose}
				open={open}>
				<div className='flex items-center justify-between mb-6 gap-5'>
					<p className='site-description-item-profile-p font-semibold text-xl my-0'>
						<QRCode size={100} value={projectDetails?.id || '-'} />
					</p>
					{/* <div className='flex items-center gap-3 bg-gray-100 p-2 py-1 rounded'>
						<Link to={`/phong/${projectDetails?.id}/cap-nhat`}>
							<FiEdit2 color='#3b82f6' size={17} />
						</Link>
						<button>
							<AiOutlineDelete color='red' size={17} />
						</button>
					</div> */}
				</div>
				<Row>
					<Col span={24}>
						<DescriptionItem title='Tên dự án' content={projectDetails?.name} />
					</Col>
					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem title='Loại' content={projectDetails?.type} />
					</Col>

					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem title='Chủ đầu tư' content={projectDetails?.fullName} />
					</Col>
					<Divider className='my-3' />

					<Col span={24}>
						<DescriptionItem title='Địa chỉ' content={projectDetails?.address} />
					</Col>
					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem
							title='Kinh phí'
							content={new Intl.NumberFormat().format(projectDetails?.expense)}
						/>
					</Col>

					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem
							title='Tổng chi'
							content={new Intl.NumberFormat().format(
								_.sumBy(
									_.filter(
										_.map(revExpand, (o) => ({...o, total: +o?.total})),
										(item) => item?.type == 0
									),
									'total'
								)
							)}
						/>
					</Col>
					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem
							title='Tổng thu'
							content={new Intl.NumberFormat().format(
								_.sumBy(
									_.filter(
										_.map(revExpand, (o) => ({...o, total: +o?.total})),
										(item) => item?.type == 1
									),
									'total'
								)
							)}
						/>
					</Col>
					<Divider className='my-3' />
					<Col span={24}>
						{projectDetails?.status == 0 && (
							<DescriptionItem
								title='Trạng thái'
								content={
									<span className='ml-2 items-center text-gray-500'>
										<Badge color='gray' />
										Chuẩn bị
									</span>
								}
							/>
						)}
						{projectDetails?.status == 1 && (
							<DescriptionItem
								title='Trạng thái'
								content={
									<span className='ml-2 items-center text-sky-500'>
										<Badge color='blue' />
										Hoạt động
									</span>
								}
							/>
						)}
						{projectDetails?.status == 2 && (
							<DescriptionItem
								title='Trạng thái'
								content={
									<span className='ml-2 items-center text-green-500'>
										<Badge color='green' />
										Hoàn thành
									</span>
								}
							/>
						)}
						{projectDetails?.status == 3 && (
							<DescriptionItem
								title='Trạng thái'
								content={
									<span className='ml-2 items-center text-rose-500'>
										<Badge color='red' />
										Hủy bỏ
									</span>
								}
							/>
						)}
					</Col>
				</Row>
			</Drawer>
		</>
	);
}

ContructionDetails.propTypes = {
	data: PropTypes?.any,
};

export default ContructionDetails;
