import {Alert, Button, Form, Input} from 'antd';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {apiCreateAccount} from '../../API/account';
import {NOTIFICATION_TYPE} from '../../Contant/global';
import {openNotification} from '../../Helper/notification';
import {
	getMessageErrors,
	optionValidate,
	validateEmail,
	validatePassword,
} from '../../Helper/vailate';

function AccountCreate(props) {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [errors, setErrors] = useState([]);
	const [loading, setLoading] = useState(false);

	const onFinish = () => {
		console.log(form.getFieldsValue());
		fetchAccountCreate();
	};

	const fetchAccountCreate = () => {
		setLoading(true);
		apiCreateAccount(form.getFieldsValue())
			.then((response) => {
				openNotification(NOTIFICATION_TYPE.success, 'Tạo mới thành công !');
			})
			.then((response) => {
				navigate('/account');
			})
			.catch((err) => {
				openNotification(NOTIFICATION_TYPE.error, 'Tạo mới thất bại !');
				console.log(err);
				setLoading(false);
			});
	};

	return (
		<div className='p-10  ' style={{width: 370}}>
			<Form
				onFinishFailed={() => {
					setErrors(getMessageErrors(form.getFieldsError()));
				}}
				onFinish={onFinish}
				form={form}
				layout='vertical'>
				<label
					htmlFor='username'
					className='block mb-2   text-gray-900 dark:text-white mt-2'>
					Tài khoản
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('username') ? '' : 'error'}
					rules={optionValidate(true, null, null, 5, 55, 'tài khoản')}
					label='Tên tài khoản'
					name={'username'}>
					<Input placeholder='Vui lòng nhập' />
				</Form.Item>
				<label
					htmlFor='username'
					className='block mb-2   text-gray-900 dark:text-white mt-2'>
					Mật khẩu
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('password') ? '' : 'error'}
					rules={[
						...optionValidate(true, null, null, null, null, 'Mật khẩu'),
						{validator: validatePassword},
					]}
					label='Mật khẩu'
					name={'password'}>
					<Input className='txtPassword' placeholder='********' />
				</Form.Item>
				<label
					htmlFor='username'
					className='block mb-2   text-gray-900 dark:text-white mt-2'>
					Email
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('email') ? '' : 'error'}
					rules={[
						...optionValidate(true, null, null, null, null, 'email'),
						{validator: validateEmail},
					]}
					label='Mail'
					name={'email'}>
					<Input placeholder='Vui lòng nhập' />
				</Form.Item>
				{errors.length > 0 && (
					<Alert
						className='rounded mt-5'
						message={
							<div className='flex flex-col text-gray-500' style={{fontSize: 13}}>
								{errors?.map((o, i) => (
									<div key={i}>{o}</div>
								))}
							</div>
						}
						type='warning'
					/>
				)}
				<div className='mt-5 '>
					<Button
						loading={loading}
						className='w-full'
						type='primary'
						htmlType='submit'>
						Tạo mới
					</Button>
				</div>
			</Form>
		</div>
	);
}

AccountCreate.propTypes = {};

export default AccountCreate;
