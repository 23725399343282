import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import BaseInput from '../../Components/BaseInput';
import {apiUpdateArise} from '../../API/arise';
// import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {optionValidate} from '../../Helper/vailate';
import {Divider} from 'antd';
import BaseSelectEdit from '../../Components/BaseSelectEdit';
import {apiUpdateSalary} from '../../API/salary';
import {apiAriseDetails} from '../../API/arise';
import BaseTextAreaEdit from '../../Components/BaseTextarea';

function AriseUpdate(props) {
	const {id} = useParams();
	const [ariseDetails, setAriseapiUpdateAriseDetails] = useState();
	// const dispatch = useDispatch();
	const getAriseAriseDetails = (id) => {
		apiAriseDetails(id)
			.then((result) => {
				setAriseapiUpdateAriseDetails(result);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(() => {
		if (id) {
			getAriseAriseDetails(id);
		}
	}, [id]);

	return (
		<div style={{width: '100%'}}>
			<div className='w-full justify-between '>
				<div className='w-5/12 p-5 ml-5 mt-5'>
					<BaseInput
						apiUpdate={apiUpdateArise}
						edit={false}
						field={'projectId'}
						id={id}
						label={'Tên dự án'}
						value={ariseDetails?.project?.name}
						rules={optionValidate(true, null, null, 5, 30, 'Tên dự án')}
					/>
					<Divider />
					<BaseInput
						apiUpdate={apiUpdateArise}
						edit={true}
						field={'name'}
						id={id}
						label={'Tiêu đề'}
						value={ariseDetails?.name}
						rules={optionValidate(true, null, null, 5, 55, 'Tiêu đề')}
					/>
					<Divider />
					<BaseTextAreaEdit
					
						apiUpdate={apiUpdateArise}
						edit={true}
						field={'note'}
						id={id}
						label={'Mô tả'}
						value={ariseDetails?.note}
						rules={optionValidate(true, null, null, 1, 2000, 'Mô tả')}
					/>
					<Divider />
				</div>
			</div>{' '}
		</div>
	);
}

AriseUpdate.propTypes = {};

export default AriseUpdate;
