import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {DownOutlined, SmileOutlined} from '@ant-design/icons';
import {Dropdown, Modal, Select} from 'antd';
import {Button, Input, Pagination, Space, Table, Tag} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {FaPlus} from 'react-icons/fa6';
import {AiOutlineHolder} from 'react-icons/ai';
import {
	apiGetListTimeWorkDetail,
	apiRemoveTimeWorkDetail,
} from '../../API/timeWorkDetails';
import TimeKeepingDetails from './TimeKeepingDetails';
import {useMitt} from 'react-mitt';
import {GrPowerReset} from 'react-icons/gr';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {apiRemoveRevExpand} from '../../API/revExpand';
import {openNotification} from '../../Helper/notification';
import {NOTIFICATION_TYPE} from '../../Contant/global';
import {apiRemoveTimeWork} from '../../API/timeKeeping';
import {FiUser} from 'react-icons/fi';

const initialValues = {
	currentPage: 1,
	perPage: 10,
	type: null,
	projectName: null,
	employeeName: null,
};

function TimeKeepingList(props) {
	const navigate = useNavigate();
	const {emitter} = useMitt();
	const [modal, contextHolder] = Modal.useModal();
	const [loading, setLoading] = useState(false);

	const [timeWorkDetails, setTimeWorkDetails] = useState([]);
	const [paramsList, setParamsList] = useState(initialValues);
	const items = (data) => [
		{
			key: '1',
			label: <TimeKeepingDetails data={data} />,
		},
		{
			key: '2',
			label: (
				<Link to={`/time-keeping/${data?.id}/update`} rel='noopener noreferrer'>
					Cập nhật
				</Link>
			),
		},

		{
			key: '4',
			danger: true,
			label: (
				<div aria-hidden onClick={() => confirm(data?.id)} style={{width: 200}}>
					Xóa bỏ
				</div>
			),
		},
	];
	const columns = [
		{
			title: '#',
			render: (text, record, index) => (
				<a href='/#'>
					{index + 1 + (paramsList?.currentPage - 1) * paramsList?.perPage}
				</a>
			),
		},

		{
			title: 'Ngày chấm',

			render: (text) => <a href='/#'>{moment(text?.date).format('DD/MM/YYYY')}</a>,
		},
		{
			title: 'Nhân công',

			render: (text) => (
				<>
					<div className='flex flex-col'>
						<a href='/#'>{text?.employee?.fullName}</a>
						<small className='text-gray-400'>{text?.employee?.idCard}</small>
					</div>
				</>
			),
		},

		{
			title: 'Thời gian',

			render: (text) => (
				<a href='/#'>
					{text?.time} <span className='text-gray-400'>( h )</span>
				</a>
			),
		},

		{
			title: 'Công trình',

			render: (text) => <a href='/#'>{text?.project?.name}</a>,
		},
		{
			title: 'Người tạo',

			render: (text) => (
				<span className='flex items-center gap-2'>
					<FiUser className='opacity-20' />
					{text?.member?.username}
				</span>
			),
		},
		{
			title: 'Ngày tạo',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (_, record) => (
				<>
					<div>{moment(record?.createdAt).format('hh:mm DD/MM/YYYY')}</div>
					<small className='text-gray-300'>
						{moment(record?.updatedAt).format('hh:mm DD/MM/YYYY')}
					</small>
				</>
			),
		},
		{
			title: 'Loại',

			render: (text) => (
				<a href='/#'>
					{text?.type == 0 ? (
						<div className='text-green-500'>Công nhật</div>
					) : (
						<div className='text-rose-500'>Tăng ca</div>
					)}
				</a>
			),
		},
		{
			title: 'Thao tác',
			key: 'action',

			render: (_, record) => (
				<Dropdown
					menu={{
						items: items(record),
					}}>
					<a
						className='text-sky-600 '
						href='#sda'
						onClick={(e) => e.preventDefault()}>
						<Space>
							<AiOutlineHolder />
						</Space>
					</a>
				</Dropdown>
			),
		},
	];
	const fetchTimeWorkList = (params) => {
		emitter.emit('pendingOn');

		apiGetListTimeWorkDetail(params)
			.then((r) => {
				console.log(r);
				setTimeWorkDetails(r);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				emitter.emit('pendingOff');
				setLoading(false);
			});
	};
	const confirm = (id) => {
		modal.confirm({
			title: 'Xác nhận',
			icon: <ExclamationCircleOutlined />,
			content: 'Bạn chắc chắn muốn xóa !',
			okText: 'Đồng ý',
			onOk: () => {
				apiRemoveTimeWorkDetail(id)
					.then((response) => {
						openNotification(NOTIFICATION_TYPE.success, 'Xóa thành công !');
					})
					.then((response) => {
						fetchTimeWorkList(paramsList);
					})
					.catch((err) => {
						openNotification(NOTIFICATION_TYPE.error, 'Xóa thất bại !');
						console.log(err);
					});
			},
		});
	};
	useEffect(() => {
		fetchTimeWorkList(paramsList);
	}, []);
	return (
		<div className='py-10'>
			{contextHolder}
			<div className='flex justify-between mb-7 px-5'>
				<div>
					<Button
						className='flex justify-center items-center gap-2'
						type='primary'
						onClick={() => {
							navigate('/time-keeping/create');
						}}>
						Tạo mới <FaPlus />
					</Button>
				</div>
				<div className='flex items-center gap-2'>
					<Select
						value={paramsList?.type}
						placeholder={'Loại'}
						onChange={(e) => setParamsList({...paramsList, type: e})}
						style={{
							width: 150,
						}}
						options={[
							{
								value: 0,
								label: 'Công nhật',
							},
							{
								value: 1,
								label: 'Tăng ca',
							},
						]}
					/>
					<Input
						value={paramsList?.projectName}
						placeholder='Tên công trình'
						style={{width: 160}}
						onChange={(e) =>
							setParamsList({...paramsList, projectName: e.target.value})
						}
					/>

					<Input
						value={paramsList?.employeeName}
						placeholder='Tên nhân công'
						style={{width: 160}}
						onChange={(e) => {
							setParamsList({...paramsList, employeeName: e.target.value});
						}}
					/>
					<Button
						onClick={() => {
							setLoading(true);

							setParamsList(initialValues);
						}}
						className=''
						type='default'>
						<GrPowerReset />
					</Button>
					<Button
						loading={loading}
						onClick={() => {
							setLoading(true);
							fetchTimeWorkList(paramsList);
						}}
						type='primary'>
						Tìm kiếm
					</Button>
				</div>
			</div>
			<Table
				columns={columns}
				dataSource={timeWorkDetails?.data}
				pagination={false}
			/>
			<div className='flex justify-end mt-5'>
				<Pagination
					pageSize={paramsList.perPage}
					current={paramsList?.currentPage}
					total={timeWorkDetails?.meta?.total}
					onChange={(v) => {
						setParamsList({...initialValues, currentPage: v});
						fetchTimeWorkList({...initialValues, currentPage: v});
					}}
				/>
			</div>
		</div>
	);
}

TimeKeepingList.propTypes = {};

export default TimeKeepingList;
