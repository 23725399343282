import {Button, Col, Collapse, Divider, Drawer, QRCode, Row} from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import BaseCalendar from '../../Components/BaseCalendar';

TimeWorkDetails.propTypes = {
	data: PropTypes.object,
};
const DescriptionItem = ({title, content}) => (
	<div className='site-description-item-profile-wrapper'>
		<p className='site-description-item-profile-p-label'>{title}:</p>
		{content}
	</div>
);

DescriptionItem.propTypes = {
	title: PropTypes.string,
	content: PropTypes.any,
};

export default function TimeWorkDetails({data}) {
	const [open, setOpen] = useState(false);
	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		console.log('data', data?.timeWorkDetail);
	}, []);

	return (
		<>
			<Button type='link' onClick={showDrawer}>
				Chi tiết
			</Button>
			<Drawer
				width={'40%'}
				placement='right'
				closable={false}
				onClose={onClose}
				open={open}>
				<div className='flex items-center  m-auto justify-between mb-6 gap-5'>
					<div className=''>
						<BaseCalendar data={data?.timeWorkDetail} />
					</div>
				</div>
			</Drawer>
		</>
	);
}
