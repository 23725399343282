import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';

function BaseChartColumn({data, categories}) {
	console.log('Data', data);
	const options = {
		chart: {
			type: 'bar',
			height: 340,
			stacked: true,
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: false,
			},
			animations: {
				enabled: false,
			},
		},
		responsive: [
			{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0,
					},
				},
			},
		],
		plotOptions: {
			bar: {
				horizontal: false,
				borderRadius: 2,
				columnWidth: '25px',
				dataLabels: {
					total: {
						enabled: false,
						style: {
							fontSize: '13px',
							fontWeight: 900,
							// backgroundColor: '#f97316',
						},
					},
				},
			},
		},
		colors: ['#f97316'],
		xaxis: {
			// type: 'datetime',
			categories: categories,
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
			crosshairs: {
				fill: {
					type: 'gradient',
					gradient: {
						colorFrom: '#ffffff',
						colorTo: '#ffffff',

						opacityFrom: 0.1,
						opacityTo: 0.1,
					},
				},
			},
			tooltip: {
				enabled: true,
			},
			lines: {
				show: true,
			},
		},
		grid: {
			strokeDashArray: 0.3, // Độ dày của đường kẻ ngang
			position: 'back', // Vị trí của đường kẻ ngang (front, back)
			border: {
				color: '#ddd', // Màu sắc của đường biên
				width: 0.5, // Độ dày của đường biên
			},
		},
		legend: {
			show: true, // Hiển thị hướng dẫn
			position: 'bottom', // Vị trí của hướng dẫn (top, right, bottom, left)
			horizontalAlign: 'center', // Canh lề ngang (left, center, right)
			floating: false, // Hướng dẫn có nổi hay không
			fontSize: '10px', // Kích thước chữ của hướng dẫn
			onItemClick: {
				toggleDataSeries: true, // Cho phép chọn để ẩn/hiện dải dữ liệu
			},
			onItemHover: {
				highlightDataSeries: true, // Cho phép hover để làm nổi bật dải dữ liệu
			},
			markers: {
				width: 8,
				height: 8,
				strokeWidth: 0,
				radius: 8,
			},
			offsetY: 8,
		},
		fill: {
			type: 'gradient',
			gradient: {
				shade: 'light',
				type: 'horizontal',
				shadeIntensity: 0.25,
				gradientToColors: undefined,
				inverseColors: true,
				opacityFrom: 0.85,
				opacityTo: 0.85,
				stops: [50, 0, 100],
			},
		},
	};
	const series = [
		{
			name: 'Tổng tiền',
			data: data,
		},
	];

	return (
		<div id='chart' className='mb-5 px-3 mx-5'>
			<ReactApexChart options={options} series={series} type='bar' height={320} />
		</div>
	);
}

BaseChartColumn.propTypes = {
	data: PropTypes.array,
	categories: PropTypes.array,
};

export default BaseChartColumn;
