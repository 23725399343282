import {axios} from '../Conf/axios';

export const apiGetListEmployee = (params) =>
	axios.get('/employee/list', {params});

export const apiGetListSalaryEmployee = (params) =>
	axios.get('/employee/salary', {params});

export const apiCreateEmployee = (data) => axios.post(`/employee/create`, data);

export const apiUpdateEmployee = (id, data) =>
	axios.put(`/employee/${id}/update`, data);

export const apiRemoveEmployee = (id) => axios.delete(`/employee/${id}/remove`);

export const apiEmployeeDetails = (id) => axios.get(`/employee/${id}/details`);

export const apiCountEmployee = () => axios.get(`/employee/count`);
