import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Cascader,
	ColorPicker,
	DatePicker,
	Divider,
	Form,
	Input,
	InputNumber,
	Radio,
	Select,
	Slider,
	Switch,
	TreeSelect,
} from 'antd';
import BaseInput from '../../Components/BaseInput';
import {apiProjectDetails, apiUpdateProject} from '../../API/project';
import {useParams} from 'react-router-dom';
import {optionValidate} from '../../Helper/vailate';
import BaseSelectEdit from '../../Components/BaseSelectEdit';

function ProjectapiUpdateProjectUpdate(props) {
	const {id} = useParams();
	const [projectDetails, setProjectDetails] = useState();
	// const dispatch = useDispatch();
	const getProjectDetails = (id) => {
		apiProjectDetails(id)
			.then((result) => {
				setProjectDetails(result);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(() => {
		if (id) {
			getProjectDetails(id);
		}
	}, [id]);
	return (
		<div style={{width: '100%'}}>
			<div className='w-full justify-between '>
				<div className='w-5/12 p-5 ml-5 mt-5'>
					<BaseInput
						apiUpdate={apiUpdateProject}
						edit={true}
						field={'name'}
						id={id}
						label={'Tên dự án'}
						value={projectDetails?.name}
						rules={optionValidate(true, null, null, 5, 25, 'Tên dự án')}
					/>
					{/* <Divider />
					<BaseSelectEdit
						apiUpdate={apiUpdateProject}
						id={id}
						edit={true}
						label={'Loại '}
						field={'type'}
						option={[
							{
								value: 'Nhà phố',
								label: 'Nhà phố',
							},
							{
								value: 'Nhà cấp 4',
								label: 'Nhà cấp 4',
							},
							{
								value: 'Nhà vườn',
								label: 'Nhà vườn',
							},
							{
								value: 'Biệt thự',
								label: 'Biệt thự',
							},
							{
								value: 'Sửa chữa',
								label: 'Sửa chữa',
							},
						]}
						value={projectDetails?.type}
						rules={optionValidate('Loại', true)}
					/> */}
					<Divider />
					<BaseInput
						apiUpdate={apiUpdateProject}
						edit={true}
						field={'type'}
						id={id}
						label={'Loại'}
						value={projectDetails?.type}
						rules={optionValidate(true, null, null, 5, 25, 'Loại dự án')}
					/>
					<Divider />
					{/* <BaseInput
				apiUpdate={apiUpdateProject}
				edit={true}
				field={'email'}
				id={id}
				label={'Mail'}
				value={projectDetails?.email}
			/> */}
					{/* <Divider /> */}

					{/* <BaseSelectEdit
						apiUpdate={apiUpdateProject}
						id={id}
						edit={true}
						label={'Giới tính'}
						field={'gender'}
						option={[
							{label: 'Nam', value: 0},
							{label: 'Nữ', value: 1},
						]}
						value={projectDetails?.gender}
						rules={optionValidate('giới tính', true)}
					/>
					<Divider /> */}
					<BaseInput
						number={true}
						apiUpdate={apiUpdateProject}
						edit={true}
						field={'expense'}
						id={id}
						label={'Kinh phí'}
						value={projectDetails?.expense}
						rules={optionValidate(
							true,
							1000000,
							100000000000000000,
							null,
							null,
							'Kinh phí'
						)}
					/>
					<Divider />
					<BaseInput
						apiUpdate={apiUpdateProject}
						edit={true}
						field={'address'}
						id={id}
						label={'Địa chỉ'}
						value={projectDetails?.address}
						rules={optionValidate(true, null, null, 5, 100, 'địa chỉ')}
					/>

					<Divider />
					{/* <BaseSelectEdit
						apiUpdate={apiUpdateProject}
						id={id}
						edit={true}
						label={'Họ tên'}
						field={'status'}
						option={[
							{label: 'Hoạt động', value: 0},
							{label: 'Ngửng hoạt động', value: 1},
						]}
						value={projectDetails?.status}
						rules={optionValidate('trạng thái', true)}
					/> */}
					<BaseInput
						apiUpdate={apiUpdateProject}
						edit={true}
						field={'fullName'}
						id={id}
						label={'Chủ đầu tư'}
						value={projectDetails?.fullName}
						rules={optionValidate('Họ tên', true, 100000, 10000000)}
					/>
					<Divider />
					<BaseSelectEdit
						apiUpdate={apiUpdateProject}
						id={id}
						edit={true}
						label={'Trạng thái'}
						field={'status'}
						option={[
							{label: 'Chuẩn bị', value: 0},
							{label: 'Hoạt động', value: 1},
							{label: 'Hoàn thành', value: 2},
							{label: 'Hủy bỏ', value: 3},
						]}
						value={projectDetails?.status}
						rules={optionValidate('Giới tính', true)}
					/>
					<Divider />

					{/* <BaseInput
						apiUpdate={apiUpdateProject}
						edit={true}
						field={'idCard'}
						id={id}
						label={'CCCD'}
						value={projectDetails?.idCard}
						rules={optionValidate('CCCD', true, 100000, 10000000)}
					/>
					<Divider /> */}
					{/* <BaseInput
						apiUpdate={apiUpdateProject}
						edit={true}
						field={'birthday'}
						id={id}
						label={'Năm sinh'}
						value={projectDetails?.birthday}
						rules={optionValidate('brithday', true, 100000, 10000000)}
					/>
					<Divider /> */}
				</div>
			</div>{' '}
		</div>
	);
}

ProjectapiUpdateProjectUpdate.propTypes = {};

export default ProjectapiUpdateProjectUpdate;
