import {SmileOutlined} from '@ant-design/icons';
import {Button, Result} from 'antd';
import React, {useEffect} from 'react';
import {TbFaceIdError} from 'react-icons/tb';
import {useNavigate} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import Loading from '../../Components/Loading';

export default function PageNotFound() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {nameHotel, username, isLogin, expiredAt} = useSelector(
		(state) => state.auth
	);
	useEffect(() => {
		// !isLogin && dispatch(getProfileAction());
		navigate('/sign-in');
	}, [isLogin]);
	return (
		<div className='h-screen flex items-center justify-center'>
			<Loading state={true} />
			{/* <Result
				className='mb-20'
				icon={<TbFaceIdError color='gray' size={100} />}
				title={<div className='text-2xl font-extrabold text-gray-500'>PAGE NOT FOUND ! </div>}
				extra={<button onClick={() => { navigate("/") }} size='large' className='px-4 py-2 rounded-sm text-blue-600 ' >Go Back Home</button>}
				status={'info'}

			/>
			<div className='fixed bottom-0 right-0 text-sm text-gray-400' style={{ fontSize: 8 }}>{window.navigator.appVersion}</div> */}
		</div>
	);
}
