import {axios} from '../Conf/axios';

export const apiGetListRevExpand = (params) =>
	axios.get('/rev-expand/list', {params});

export const apiCreateRevExpand = (data) =>
	axios.post(`/rev-expand/create`, data);

export const apiUpdateRevExpand = (id, data) =>
	axios.put(`/rev-expand/${id}/update`, data);

export const apiRemoveRevExpand = (id) =>
	axios.delete(`/rev-expand/${id}/remove`);

export const apiRevExpandDetails = (id) =>
	axios.get(`/rev-expand/${id}/details`);
