import logo from './logo.svg';
import './App.css';

import MainLayout from './Layout/MainLayout';
import {Route, Routes} from 'react-router-dom';
import Employee from './Views/Employee/Employee';
import EmployeeDetails from './Views/Employee/EmployeeDetails';
import EmployeeCreate from './Views/Employee/EmployeeCreate';
import EmployeeUpdate from './Views/Employee/EmployeeUpdate';
import ConstructionList from './Views/Contruction/ConstructionList';
import ContructionDetails from './Views/Contruction/ContructionDetails';
import ContructionUpdate from './Views/Contruction/ContructionUpdate';
import ContructionCreate from './Views/Contruction/ContructionCreate';
import RevExpandList from './Views/RevExpand/RevExpandList';
import RevExpandCreate from './Views/RevExpand/RevExpandCreate';
import RevExpandDetails from './Views/RevExpand/RevExpandDetails';
import RevExpandUpdate from './Views/RevExpand/RevExpandUpdate';
import TimeWorkList from './Views/TimeWork/TimeWorkLlist';
import TimeWorkDetails from './Views/TimeWork/TimeWorkDetails';
import TimeKeepingCreate from './Views/TimeKeeping/TimeKeepingCreate';
import SignUp from './Views/Auth/SignUp';
import Signin from './Views/Auth/Signin';
import TimeKeepingUpdate from './Views/TimeKeeping/TimeKeepingUpdate';
import TimeKeepingList from './Views/TimeKeeping/TimeKeepingList';
import AccountUpdate from './Views/Account/AccountUpdate';
import AccountCreate from './Views/Account/AccountCreate';
import AccountList from './Views/Account/AccountList';
import HomeList from './Views/Home/HomeList';
import {useSelector} from 'react-redux';
import RevExpandItemDetailUpdate from './Views/RevExpand/RevExpandDetailUpdate';
import AccountDetail from './Views/Account/AccountDetail';
import ChangePassword from './Views/Account/ChangePassword';
import RevExpandNotPriceList from './Views/RevExpandNotPrice/RevExpandNotPriceList';
import EquipmentDetails from './Views/Equipment/EquipmentDetails';
import EquipmentCreate from './Views/Equipment/EquipmentCreate';
import EquipmentUpdate from './Views/Equipment/EquipmentUpdate';
import EquipmentList from './Views/Equipment/Equipment';
import AriseList from './Views/Arise/AriseList';
import AriseDetails from './Views/Arise/AriseDetails';
import AriseCreate from './Views/Arise/AriseCreate';
import AriseUpdate from './Views/Arise/AriseUpdate';
import PageNotFound from './Views/Exception/NotFound';

function App() {
	const {isLogin} = useSelector((state) => state.auth);

	return (
		<Routes>
			{isLogin && (
				<Route path='/' element={<MainLayout />}>
					<Route path='employee' element={<Employee />} />
					<Route path='employee/:id/details' element={<EmployeeDetails />} />
					<Route path='employee/create' element={<EmployeeCreate />} />
					<Route path='employee/:id/update' element={<EmployeeUpdate />} />

					<Route path='equipment' element={<EquipmentList />} />
					<Route path='equipment/:id/details' element={<EquipmentDetails />} />
					<Route path='equipment/create' element={<EquipmentCreate />} />
					<Route path='equipment/:id/update' element={<EquipmentUpdate />} />

					<Route path='arise' element={<AriseList />} />
					<Route path='arise/:id/details' element={<AriseDetails />} />
					<Route path='arise/create' element={<AriseCreate />} />
					<Route path='arise/:id/update' element={<AriseUpdate />} />

					<Route path='construction' element={<ConstructionList />} />
					<Route path='construction/:id/details' element={<ContructionDetails />} />
					<Route path='construction/create' element={<ContructionCreate />} />
					<Route path='construction/:id/update' element={<ContructionUpdate />} />

					<Route path='rev-expand' element={<RevExpandList />} />
					<Route path='rev-expand/:id/details' element={<RevExpandDetails />} />
					<Route path='rev-expand/create' element={<RevExpandCreate />} />
					<Route path='rev-expand/:id/update' element={<RevExpandUpdate />} />
					<Route
						path='rev-expand-item/:id/update'
						element={<RevExpandItemDetailUpdate />}
					/>

					<Route path='time-work' element={<TimeWorkList />} />

					<Route path='time-work/:id/details' element={<TimeWorkDetails />} />
					<Route path='time-keeping/create' element={<TimeKeepingCreate />} />
					<Route path='time-keeping' element={<TimeKeepingList />} />
					<Route path='time-keeping/:id/update' element={<TimeKeepingUpdate />} />

					<Route path='account/:id/update' element={<AccountUpdate />} />
					<Route path='account/create' element={<AccountCreate />} />
					<Route path='account/details' element={<AccountDetail />} />
					<Route path='account/change-password' element={<ChangePassword />} />
					<Route path='account' element={<AccountList />} />

					<Route path='rev-expand-not-price' element={<RevExpandNotPriceList />} />

					<Route
						path='rev-expand-not-price/:id/update'
						element={<RevExpandUpdate />}
					/>
					<Route
						path='rev-expand-item/:id/update'
						element={<RevExpandItemDetailUpdate />}
					/>

					<Route path='/' element={<HomeList />} />
				</Route>
			)}

			<Route path='/sign-up' element={<SignUp />} />

			<Route path='/sign-in' element={<Signin />} />
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
}

export default App;
