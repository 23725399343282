import {Col, Divider, Drawer, QRCode, Row} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

RevExpandDetails.propTypes = {
	data: PropTypes.object,
};
const DescriptionItem = ({title, content}) => (
	<div className='site-description-item-profile-wrapper'>
		<p className='site-description-item-profile-p-label'>{title}:</p>
		{content}
	</div>
);

DescriptionItem.propTypes = {
	title: PropTypes.string,
	content: PropTypes.any,
};

export default function RevExpandDetails({data}) {
	const [open, setOpen] = useState(false);
	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	const getBillTotal = (data) => {
		return _.reduce(
			data,
			(sum, item) => {
				console.log('item', item);
				return sum + +item?.price * +item?.quanlity;
			},
			0
		);
	};
	useEffect(() => {
		console.log(data);
	}, []);

	return (
		<>
			<button onClick={showDrawer}>Chi tiết</button>
			<Drawer
				width={340}
				placement='right'
				closable={false}
				onClose={onClose}
				open={open}>
				<div className='flex items-center justify-between mb-6 gap-5'>
					<p className='site-description-item-profile-p font-semibold text-xl my-0'>
						<QRCode size={100} value={data?.id || '-'} />
					</p>
					{/* <div className='flex items-center gap-3 bg-gray-100 p-2 py-1 rounded'>
						<Link to={`/phong/${data?.id}/cap-nhat`}>
							<FiEdit2 color='#3b82f6' size={17} />
						</Link>
						<button>
							<AiOutlineDelete color='red' size={17} />
						</button>
					</div> */}
				</div>
				<Row>
					<Col span={24}>
						<DescriptionItem
							title='Loại'
							content={
								data?.type == 1 ? (
									<span
										className='rounded-sm text-start text-green-500 '
										style={{width: 120}}
										color='#87d068'>
										Thu vào
									</span>
								) : (
									<span
										className='rounded-sm text-start text-rose-500  '
										style={{width: 120}}>
										Chi ra
									</span>
								)
							}
						/>
					</Col>
					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem title='Tiêu đề' content={data?.title} />
					</Col>

					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem
							title='Số tiền'
							content={new Intl.NumberFormat().format(
								getBillTotal(data?.revexpanditem)
							)}
						/>
					</Col>
					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem
							title='Thời gian'
							content={moment(data?.date).format('DD/MM/YYYY')}
						/>
					</Col>
					<Divider className='my-3' />

					<Col span={24}>
						<DescriptionItem title='Công trình' content={data?.project?.name} />
					</Col>
					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem title='Người tạo' content={data?.member?.username} />
					</Col>
					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem
							title='Ngày tạo'
							content={moment(data?.createdAt).format('DD/MM/YYYY')}
						/>
					</Col>
					<Divider className='my-3' />

					<Col span={24}>
						<DescriptionItem title='Đối tác' content={data?.supplier} />
					</Col>
					<Divider className='my-3' />

					{data?.revexpanditem?.map((item, index) => (
						<>
							<Col span={24}>
								<DescriptionItem
									title={index + 1 + '. ' + item?.name}
									content={`${item?.quanlity}x${new Intl.NumberFormat().format(item?.price)}=${new Intl.NumberFormat().format(+item?.quanlity * +item?.price)}`}
								/>
							</Col>
							<Divider className='my-3' />
						</>
					))}
				</Row>
			</Drawer>
		</>
	);
}
