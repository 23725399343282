import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
	Alert,
	Button,
	Cascader,
	ColorPicker,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Radio,
	Select,
	Slider,
	Switch,
	TreeSelect,
} from 'antd';
import {openNotification} from '../../Helper/notification';
import {NOTIFICATION_TYPE} from '../../Contant/global';
import {useNavigate} from 'react-router-dom';
import {getMessageErrors, optionValidate} from '../../Helper/vailate';
import {apiCreateEquipment} from '../../API/equipment';

function EquipmentCreate(props) {
	const [form] = Form.useForm();
	const [errors, setErrors] = useState([]);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();
	const onFinish = () => {
		console.log(form.getFieldsValue());
		fetchEquipmentCreate();
	};

	const fetchEquipmentCreate = () => {
		setLoading(true);
		const all = form.getFieldsValue();
		all.date = '10/09/1995';
		apiCreateEquipment(all)
			.then((response) => {
				openNotification(NOTIFICATION_TYPE.success, 'Tạo mới thành công !');
			})
			.then((response) => {
				navigate('/equipment');
			})
			.catch((err) => {
				openNotification(NOTIFICATION_TYPE.error, 'Tạo mới thất bại !');
				console.log(err);
				setLoading(false);
			});
	};

	return (
		<div className='px-8  ' style={{width: 360}}>
			<Form
				onFinish={onFinish}
				onFinishFailed={() => {
					setErrors(getMessageErrors(form.getFieldsError()));
				}}
				form={form}
				layout='vertical'>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Tên
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('name') ? '' : 'error'}
					rules={optionValidate(true, null, null, 5, 50, 'Tên')}
					label='Tên'
					name={'name'}>
					<Input placeholder='Vui lòng nhập' />
				</Form.Item>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Mã vật tư
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('code') ? '' : 'error'}
					rules={optionValidate(true, null, null, 1, 50, 'Mã vật tư')}
					label='Mã vật tư'
					name={'code'}>
					<Input placeholder='Vui lòng nhập' />
				</Form.Item>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Serial
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('serial') ? '' : 'error'}
					rules={optionValidate(true, null, null, 1, 50, 'serial')}
					label='Serial'
					name={'serial'}>
					<Input placeholder='Vui lòng nhập' />
				</Form.Item>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Nhà cung cấp
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('prodcucer') ? '' : 'error'}
					rules={optionValidate(true, null, null, 3, 50, 'Nhà sản xuất')}
					label='Nhà sản xuất'
					name={'prodcucer'}>
					<Input placeholder='Vui lòng nhập' />
				</Form.Item>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Đơn vị
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('unit') ? '' : 'error'}
					rules={optionValidate(true, null, null, 1, 20, '	Đơn vị')}
					label='	Đơn vị'
					name={'unit'}>
					<Input placeholder='Vui lòng nhập' />
				</Form.Item>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Số lượng
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('quanlity') ? '' : 'error'}
					rules={optionValidate(true, 1, 100000, null, null, '	Số lượng')}
					label='	Số lượng'
					name={'quanlity'}>
					<InputNumber className='w-full' placeholder='Vui lòng nhập' />
				</Form.Item>
				{errors.length > 0 && (
					<Alert
						className='rounded mt-5'
						message={
							<div className='flex flex-col text-gray-500' style={{fontSize: 13}}>
								{errors?.map((o, i) => (
									<div key={i}>{o}</div>
								))}
							</div>
						}
						type='warning'
					/>
				)}

				<div className='  mt-5'>
					<Button
						loading={loading}
						className='w-full'
						type='primary'
						htmlType='submit'>
						Tạo mới
					</Button>
				</div>
			</Form>
		</div>
	);
}

EquipmentCreate.propTypes = {};

export default EquipmentCreate;
