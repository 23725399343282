import {Button, Divider, Dropdown, Layout, Space, theme} from 'antd';
import React, {useEffect, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {BsBuildings, BsGrid3X2Gap} from 'react-icons/bs';
import {FaRegMoneyBill1} from 'react-icons/fa6';
import {BiQr} from 'react-icons/bi';
import {FaRandom} from 'react-icons/fa';
import {FaUserGroup} from 'react-icons/fa6';
import {DownOutlined, SmileOutlined} from '@ant-design/icons';
import {FaUserCircle} from 'react-icons/fa';
import {
	AiOutlineArrowsAlt,
	AiOutlineCaretLeft,
	AiOutlineCaretRight,
	AiOutlineHome,
} from 'react-icons/ai';
import Loading from '../Components/Loading';
import {useMitt} from 'react-mitt';
import {IoArrowBackOutline, IoArrowUndoSharp} from 'react-icons/io5';
import {GrLinkNext} from 'react-icons/gr';
import {useDispatch, useSelector} from 'react-redux';
import {evtLogout} from '../Store/reducers/auth';

const {Header, Sider, Content} = Layout;
const MainLayout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {username} = useSelector((state) => state?.auth);
	const {emitter} = useMitt();
	const [pending, setPending] = useState(false);
	const items = [
		{
			key: '1',
			label: (
				<div
					aria-hidden
					type='link'
					onClick={(e) => {
						navigate('/account/details');
					}}>
					Thông tin
				</div>
			),
		},
		{
			key: '2',
			label: (
				<div
					aria-hidden
					type='link'
					onClick={(e) => {
						navigate('/account/change-password');
					}}>
					Đổi mật khẩu
				</div>
			),
		},
		{
			key: '4',
			danger: true,
			label: (
				<div
					style={{width: 150}}
					aria-hidden
					type='link'
					onClick={(e) => {
						dispatch(evtLogout());
					}}>
					Đăng xuất
				</div>
			),
		},
	];
	useEffect(() => {
		// listen and respond to 'foo' events
		emitter.on('pendingOn', (e) => setPending(true));
		emitter.on('pendingOff', (e) => {
			setTimeout(() => setPending(false), 700);
		});
	}, []);
	return (
		<>
			<Loading state={pending} />

			<div className='flex'>
				<div
					className='border-r fixed left-0 top-0 h-screen  bg-white'
					style={{width: 220}}>
					<div className='font-bold text-2xl  text-center  mt-5 '>
						V
						<span className='font-bold italic text-3xl text-center  text-orange-500   mt-5 '>
							NN
						</span>
						IX
					</div>
					<div className='font-thin text-sm text-center mb-5 italic text-gray-500'>
						Software V1.0.1
					</div>
					<div className='flex flex-col p-5 px-7 gap-2 justify-start items-star text-sm'>
						<button
							className='flex items-center gap-2 text-orange-600'
							onClick={() => {
								navigate('/');
							}}
							type='link'>
							<AiOutlineHome />
							Trang chủ
						</button>
						<Divider className='my-5  ' />
						<button
							className='flex items-center gap-2 text-orange-600'
							onClick={() => {
								navigate('/construction');
							}}
							type='link'>
							<BsBuildings />
							Dự án
						</button>
						<Divider className='my-5  ' />
						<button
							className='flex items-center gap-2 text-orange-600'
							onClick={() => {
								navigate('/time-work');
							}}
							type='link'>
							<FaRegMoneyBill1 />
							Tiền Công
						</button>
						<Divider className='my-5  ' />
						<button
							className='flex items-center gap-2 text-orange-600'
							onClick={() => {
								navigate('/time-keeping');
							}}
							type='link'>
							<BiQr />
							Chấm Công
						</button>
						<Divider className='my-5  ' />
						<button
							className='flex items-center gap-2 text-orange-600'
							type='link'
							onClick={() => {
								navigate('/rev-expand');
							}}>
							<FaRandom />
							Thu chi
						</button>
						<Divider className='my-5  ' />
						<button
							onClick={() => {
								navigate('/employee');
							}}
							className='flex items-center gap-2 text-orange-600'
							type='link'>
							<FaUserGroup />
							Nhân công
						</button>
						<Divider className='my-5  ' />
						<button
							className='flex items-center gap-2  text-orange-600'
							onClick={() => {
								navigate('/arise');
							}}
							type='link'>
							<AiOutlineArrowsAlt />
							Phát sinh
						</button>
						<Divider className='my-5  ' />
						<button
							className='flex items-center gap-2  text-orange-600'
							onClick={() => {
								navigate('/equipment');
							}}
							type='link'>
							<BsGrid3X2Gap />
							Vật tư
						</button>
						<Divider className='my-5  ' />
						<button
							onClick={() => {
								navigate('/account');
							}}
							className='flex items-center gap-2 text-orange-600'
							type='link'>
							<FaUserGroup />
							Tài khoản
						</button>
					</div>

					<div className='flex flex-col justify-start'></div>
				</div>
				<div className='w-full' style={{marginLeft: 220}}>
					<div className='flex bg-white shadow justify-between items-center px-10 py-4'>
						<div>
							<Button
								onClick={() => {
									navigate(-1);
								}}
								type='link'
								className='flex items-center gap-2'>
								<AiOutlineCaretLeft />
								Back
							</Button>
						</div>
						<div className='font-extrabold text-xl text-gray-800 opacity-10'>
							TƯ VẤN - THIẾT KẾ - XÂY DỰNG KIẾN HƯNG
						</div>
						<Dropdown
							menu={{
								items,
							}}>
							<a href='#d'>
								<Space>
									{username || 'NoName'}
									<FaUserCircle />
								</Space>
							</a>
						</Dropdown>
					</div>
					<Content className=''>
						<Outlet />
					</Content>
				</div>
			</div>
		</>
	);
};
export default MainLayout;
