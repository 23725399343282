import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {DownOutlined, SmileOutlined} from '@ant-design/icons';
import {Dropdown, Modal, Select} from 'antd';
import {Button, Input, Pagination, Space, Table, Tag} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {FaPlus} from 'react-icons/fa6';
import {AiOutlineHolder} from 'react-icons/ai';
import {
	apiCreateRevExpand,
	apiGetListRevExpand,
	apiRemoveRevExpand,
} from '../../API/revExpand';
import RevExpandNotPriceDetails from './RevExpandNotPriceDetails';
import {openNotification} from '../../Helper/notification';
import {NOTIFICATION_TYPE} from '../../Contant/global';
import _ from 'lodash';
import {useMitt} from 'react-mitt';
import {GrPowerReset} from 'react-icons/gr';
import readXlsxFile from 'read-excel-file';
import {apiCreateTimeWork} from '../../API/timeKeeping';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {
	apiGetListRevExpandItem,
	apiRemoveRevExpandItem,
} from '../../API/revExpandItem';

const initialValues = {
	currentPage: 1,
	perPage: 10,
	type: null,
	supplier: null,
	name: null,
};

function RevExpandNotPriceList(props) {
	const navigate = useNavigate();
	const [revRexpandItem, setRevRexpandItem] = useState([]);
	const [paramsList, setParamsList] = useState(initialValues);
	const {emitter} = useMitt();
	const [modal, contextHolder] = Modal.useModal();

	const fetchRevExpandNotPriceList = (params) => {
		emitter.emit('pendingOn');
		apiGetListRevExpandItem(params)
			.then((r) => {
				console.log(r);
				setRevRexpandItem(r);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				emitter.emit('pendingOff');
			});
	};

	const getBillTotal = (data) => {
		return _.reduce(
			data,
			(index, item) => {
				console.log('item', item);
				return +item?.price * +item?.quanlity;
			},
			0
		);
	};
	const confirm = (id) => {
		modal.confirm({
			title: 'Xác nhận',
			icon: <ExclamationCircleOutlined />,
			content: 'Bạn chắc chắn muốn xóa !',
			okText: 'Đồng ý',
			onOk: () => {
				console.log('sadfads');
				fetchRemoveRevExpand(id);
			},
		});
	};
	const items = (data) => [
		// {
		// 	key: '1',
		// 	label: <RevExpandNotPriceDetails data={data} />,
		// },
		{
			key: '2',
			label: (
				<Link to={`/rev-expand-item/${data?.id}/update`} rel='noopener noreferrer'>
					Cập nhật
				</Link>
			),
		},

		{
			key: '4',
			danger: true,
			label: (
				<div aria-hidden onClick={() => confirm(data?.id)} style={{width: 200}}>
					Xóa bỏ
				</div>
			),
		},
	];
	const columns = [
		{
			title: '#',

			render: (text, record, index) => (
				<a href='/#'>
					{index + 1 + (paramsList?.currentPage - 1) * paramsList?.perPage}
				</a>
			),
		},
		// {
		// 	title: 'Loại',
		// 	dataIndex: 'type',
		// 	key: 'type',
		// 	render: (text) => {
		// 		return text == 1 ? (
		// 			<div className='rounded-sm text-start text-green-500 ' color='#87d068'>
		// 				Thu vào
		// 			</div>
		// 		) : (
		// 			<div className='rounded-sm text-start text-rose-500  '>Chi ra</div>
		// 		);
		// 	},
		// },
		{
			title: 'Tiêu đề',
			dataIndex: 'name',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Thời gian',
			dataIndex: 'date',
			render: (_, record) => (
				<span>{moment(record?.revEpand?.date).format('DD/MM/YYYY')}</span>
			),
		},
		// {
		// 	title: 'Số lượng',

		// 	render: (_, record) => (
		// 		<a href='/#'>
		// 			{record.quanlity}
		// 			<span className='text-gray-300 ml-2'>{record?.unit}</span>
		// 		</a>
		// 	),
		// },
		// {
		// 	title: 'Thành tiền',
		// 	dataIndex: 'value',
		// 	key: 'value',
		// 	render: (text) => <a href='/#'>{text.toLocaleString('it-IT')}</a>,
		// },

		{
			title: 'Nhà cung cấp',
			dataIndex: 'supplier',
			key: 'supplier',
			render: (_, record) => (
				<a href='/#'>{record?.revExpand?.supplier || 'Không có'}</a>
			),
		},
		{
			title: 'Tổng tiền',

			render: (_, record) => (
				<>
					<div>
						<small>
							{record?.quanlity}({record?.unit}) x{' '}
							{new Intl.NumberFormat().format(record?.price)}
						</small>
					</div>
					<div className='text-rose-500'>
						{new Intl.NumberFormat().format(+record?.quanlity * +record?.price) ||
							'-'}
					</div>
				</>
			),
		},
		{
			title: 'Công trình',

			render: (_, record) => <a href='/#'>{record?.revexpand?.project?.name}</a>,
		},

		{
			title: 'Ngày tạo',
			render: (text) => (
				<a href='/#'>
					{
						<>
							<div>{moment(text?.createdAt).format('HH:mm DD/MM/YYYY')}</div>
							<small className='text-gray-300'>
								{moment(text?.createdAt).format('HH:mm DD/MM/YYYY')}
							</small>
						</>
					}
				</a>
			),
		},
		{
			title: 'Thao tác',
			key: 'action',

			render: (_, record) => (
				<Dropdown
					menu={{
						items: items(record),
					}}>
					<a
						className='text-sky-600 '
						href='#sda'
						onClick={(e) => e.preventDefault()}>
						<Space>
							<AiOutlineHolder />
						</Space>
					</a>
				</Dropdown>
			),
		},
	];

	const fetchRemoveRevExpand = (id) => {
		apiRemoveRevExpandItem(id)
			.then((response) => {
				openNotification(NOTIFICATION_TYPE.success, 'Xóa thành công !');
			})
			.then((r) => {
				fetchRevExpandNotPriceList(paramsList);
			})
			.catch((err) => {
				openNotification(NOTIFICATION_TYPE.error, 'Xóa thất bại !');
				console.log(err);
			});
	};
	useEffect(() => {
		fetchRevExpandNotPriceList(paramsList);
	}, []);
	return (
		<div className='py-10'>
			{contextHolder}
			<div className='flex justify-between mb-7 px-5'>
				<div className='flex items-center gap-2'>
					<div></div>
				</div>
				<div className='flex items-center gap-2'>
					<Input
						value={paramsList?.projectName}
						placeholder='Công trình'
						style={{width: 160}}
						onChange={(e) =>
							setParamsList({...paramsList, projectName: e.target.value})
						}
					/>

					<Select
						value={paramsList?.price}
						placeholder={'Chọn giá'}
						onChange={(e) => setParamsList({...paramsList, price: e})}
						style={{
							width: 150,
						}}
						options={[
							{
								value: 0,
								label: 'Chưa có giá',
							},
						]}
					/>
					<Input
						value={paramsList?.name}
						placeholder='Nhập tiêu đề'
						style={{width: 160}}
						onChange={(e) => setParamsList({...paramsList, name: e.target.value})}
					/>
					<Button
						onClick={() => {
							setParamsList(initialValues);
						}}
						className=''
						type='default'>
						<GrPowerReset />
					</Button>
					<Button
						onClick={() => fetchRevExpandNotPriceList(paramsList)}
						type='primary'>
						Tìm kiếm
					</Button>
				</div>
			</div>
			<Table
				columns={columns}
				dataSource={revRexpandItem?.data}
				pagination={false}
			/>
			<div className='flex justify-end mt-5'>
				<Pagination
					pageSize={paramsList.perPage}
					current={paramsList?.currentPage}
					total={revRexpandItem?.meta?.total}
					onChange={(v) => {
						setParamsList({...initialValues, currentPage: v});
						fetchRevExpandNotPriceList({...initialValues, currentPage: v});
					}}
				/>
			</div>
		</div>
	);
}

RevExpandNotPriceList.propTypes = {};

export default RevExpandNotPriceList;
