import {axios} from '../Conf/axios';

export const apiGetListTimeWorkDetail = (params) =>
	axios.get('/timeWorkDetails/list', {params});

export const apiCreateTimeWorkDetail = (data) =>
	axios.post(`/timeWorkDetails/create`, data);

export const apiUpdateTimeWorkDetail = (id, data) =>
	axios.put(`/timeWorkDetails/${id}/update`, data);

export const apiRemoveTimeWorkDetail = (id) =>
	axios.delete(`/timeWorkDetails/${id}/remove`);

export const apiTimeWorkDetails = (id) =>
	axios.get(`/timeWorkDetails/${id}/details`);
