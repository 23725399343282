import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
	Alert,
	Button,
	Cascader,
	ColorPicker,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Radio,
	Select,
	Slider,
	Switch,
	TreeSelect,
} from 'antd';
import {apiCreateEmployee} from '../../API/employee';
import {openNotification} from '../../Helper/notification';
import {NOTIFICATION_TYPE} from '../../Contant/global';
import {useNavigate} from 'react-router-dom';
import {getMessageErrors, optionValidate} from '../../Helper/vailate';

function EmployeeCreate(props) {
	const [form] = Form.useForm();
	const [errors, setErrors] = useState([]);

	const navigate = useNavigate();
	const onFinish = () => {
		console.log(form.getFieldsValue());
		fetchEmployeeCreate();
	};

	const fetchEmployeeCreate = () => {
		const all = form.getFieldsValue();
		const createSalary = {
			wage: all?.wage,
			wageOvertime: all?.wageOvertime,
		};
		delete all?.wage;
		delete all?.wageOvertime;
		const data = {
			createSalary,
			createEmployeeDto: {
				...all,
				birthday: new Date(form.getFieldValue('birthday')).getFullYear(),
			},
		};
		apiCreateEmployee(data)
			.then((response) => {
				openNotification(NOTIFICATION_TYPE.success, 'Tạo mới thành công !');
			})
			.then((response) => {
				navigate('/employee');
			})
			.catch((err) => {
				openNotification(NOTIFICATION_TYPE.error, 'Tạo mới thất bại !');
				console.log(err);
			});
	};

	return (
		<div className='px-8  ' style={{width: 360}}>
			<Form
				onFinish={onFinish}
				onFinishFailed={() => {
					setErrors(getMessageErrors(form.getFieldsError()));
				}}
				form={form}
				layout='vertical'>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Họ tên
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('fullName') ? '' : 'error'}
					rules={optionValidate(true, null, null, 5, 25, 'họ tên')}
					label='Họ Tên'
					name={'fullName'}>
					<Input placeholder='Vui lòng nhập' />
				</Form.Item>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Căn Cước
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('idCard') ? '' : 'error'}
					rules={optionValidate(true, null, null, 10, 20, 'Căn Cước')}
					label='Căn Cước'
					name={'idCard'}>
					<Input placeholder='Vui lòng nhập' />
				</Form.Item>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Số điện thoại
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('phone') ? '' : 'error'}
					rules={optionValidate(true, null, null, 10, 11, 'Số điện thoại')}
					label='Số điện thoại'
					name={'phone'}>
					<Input placeholder='Vui lòng nhập' />
				</Form.Item>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Ngày sinh
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('birthday') ? '' : 'error'}
					rules={optionValidate(true, null, null, null, null, 'Ngày sinh')}
					label='Ngày sinh'
					name={'birthday'}>
					<DatePicker picker='year' className='w-full' />
				</Form.Item>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Giới Tính
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('gender') ? '' : 'error'}
					rules={optionValidate(true, null, null, null, null, 'Giới Tính')}
					label='Giới Tính'
					name={'gender'}>
					<Select
						placeholder='Vui lòng chọn'
						className='w-full'
						options={[
							{
								value: 0,
								label: 'Nam',
							},
							{
								value: 1,
								label: 'Nữ',
							},
						]}
					/>
				</Form.Item>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Mức lương (ca)
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('wage') ? '' : 'error'}
					rules={optionValidate(true, 1, 10000000, null, null, 'Mức lương')}
					label='Mức lương (ca)'
					name={'wage'}>
					<InputNumber className='w-full' placeholder='Vui lòng nhập' />
				</Form.Item>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Tăng ca (h)
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('wageOvertime') ? '' : 'error'}
					rules={optionValidate(true, 1, 10000000, null, null, 'Tăng ca')}
					label='Tăng ca (h)'
					name={'wageOvertime'}>
					<InputNumber className='w-full' placeholder='Vui lòng nhập' />
				</Form.Item>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Địa chỉ
				</label>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('address') ? '' : 'error'}
					rules={optionValidate(true, null, null, 5, 50, 'địa chỉ')}
					label='Địa chỉ'
					name={'address'}>
					<Input placeholder='Vui lòng nhập' />
				</Form.Item>
				{errors.length > 0 && (
					<Alert
						className='rounded mt-5'
						message={
							<div className='flex flex-col text-gray-500' style={{fontSize: 13}}>
								{errors?.map((o, i) => (
									<div key={i}>{o}</div>
								))}
							</div>
						}
						type='warning'
					/>
				)}

				<div className='flex justify-between  mt-5'>
					<Button onClick={() => navigate(-1)}>Trở lại</Button>
					<Button className='' type='primary' htmlType='submit'>
						Tạo mới
					</Button>
				</div>
			</Form>
		</div>
	);
}

EmployeeCreate.propTypes = {};

export default EmployeeCreate;
