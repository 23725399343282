import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {DownOutlined, SmileOutlined} from '@ant-design/icons';
import {Dropdown} from 'antd';
import {Button, Input, Pagination, Space, Table, Tag} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {FaPlus} from 'react-icons/fa6';
import {AiOutlineHolder} from 'react-icons/ai';
import {useMitt} from 'react-mitt';
import {apiGetListAccount, apiRemoveAccount} from '../../API/account';
import {openNotification} from '../../Helper/notification';
import {NOTIFICATION_TYPE} from '../../Contant/global';
const initialValues = {
	currentPage: 1,
	perPage: 10,
};

function AccountList(props) {
	const navigate = useNavigate();
	const [accounts, setAccounts] = useState([]);
	const [paramsList, setParamsList] = useState(initialValues);
	const {emitter} = useMitt();
	const items = (data) => [
		{
			key: '2',
			label: (
				<Link to={`/account/${data?.id}/update`} rel='noopener noreferrer'>
					Cập nhật
				</Link>
			),
		},
		{
			key: '5',
			label: (
				<Link to={'/AccountList/1/update'} rel='noopener noreferrer'>
					Đổi mật khẩu
				</Link>
			),
		},
		{
			key: '4',
			danger: true,

			label: (
				<div
					aria-hidden
					onClick={() => fetchRemoveAccount(data?.id)}
					style={{width: 200}}>
					Xóa bỏ
				</div>
			),
		},
	];
	const columns = [
		{
			title: '#',

			render: (text, record, index) => (
				<a href='/#'>
					{index + 1 + (paramsList?.currentPage - 1) * paramsList?.perPage}
				</a>
			),
		},

		{
			title: 'Tài khoản',
			dataIndex: 'username',
			key: 'username',
			render: (text) => <a href='/#'>{text}</a>,
		},
		// {
		// 	title: 'Mật khẩu',
		// 	dataIndex: 'password',
		// 	key: 'password',
		// 	render: (text) => <a href='/#'>{text}</a>,
		// },
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (text) => <a href='/#'>{text}</a>,
		},

		{
			title: 'Ngày tạo',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text) => <a href='/#'>10:20 10/10/2023</a>,
		},
		{
			title: 'Thao tác',
			key: 'action',

			render: (_, record) => (
				<Dropdown
					menu={{
						items: items(record),
					}}>
					<a
						className='text-sky-600 '
						href='#sda'
						onClick={(e) => e.preventDefault()}>
						<Space>
							<AiOutlineHolder />
						</Space>
					</a>
				</Dropdown>
			),
		},
	];
	const fetchAccountList = (params) => {
		emitter.emit('pendingOn');
		apiGetListAccount(params)
			.then((r) => {
				console.log('r', r);
				setAccounts(r);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				emitter.emit('pendingOff');
			});
	};

	const fetchRemoveAccount = (id) => {
		apiRemoveAccount(id)
			.then((response) => {
				openNotification(NOTIFICATION_TYPE.success, 'Xóa thành công !');
			})
			.then((r) => {
				fetchAccountList(paramsList);
			})
			.catch((err) => {
				openNotification(NOTIFICATION_TYPE.error, 'Xóa thất bại !');
				console.log(err);
			});
	};
	useEffect(() => {
		fetchAccountList(paramsList);
	}, []);
	return (
		<div className='py-10'>
			<div className='flex justify-end mb-7 px-5'>
				<div>
					<Button
						className='flex justify-center items-center gap-2'
						type='primary'
						onClick={() => {
							navigate('/account/create');
						}}>
						Tạo mới <FaPlus />
					</Button>
				</div>
			</div>
			<Table columns={columns} dataSource={accounts || []} pagination={false} />
			{/* <div className='flex justify-end mt-5'>
				<Pagination
					pageSize={paramsList.perPage}
					current={paramsList?.currentPage}
					total={employees?.meta?.total}
					onChange={(v) => {
						setParamsList({...initialValues, currentPage: v});
					}}
				/> */}
			{/* </div> */}
		</div>
	);
}

AccountList.propTypes = {};

export default AccountList;
