import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {DownOutlined, SmileOutlined} from '@ant-design/icons';
import {Dropdown, Modal, Select} from 'antd';
import {Button, Input, Pagination, Space, Table, Tag} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {FaPlus} from 'react-icons/fa6';
import {AiOutlineHolder} from 'react-icons/ai';
import {
	apiCreateRevExpand,
	apiGetListRevExpand,
	apiRemoveRevExpand,
} from '../../API/revExpand';
import RevExpandDetails from './RevExpandDetails';
import {openNotification} from '../../Helper/notification';
import {NOTIFICATION_TYPE} from '../../Contant/global';
import _ from 'lodash';
import {useMitt} from 'react-mitt';
import {GrPowerReset} from 'react-icons/gr';
import readXlsxFile from 'read-excel-file';
import {apiCreateTimeWork} from '../../API/timeKeeping';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {MdMoneyOffCsred} from 'react-icons/md';
import {BiDetail} from 'react-icons/bi';
import {FiUser} from 'react-icons/fi';

const initialValues = {
	currentPage: 1,
	perPage: 10,
	type: null,
	supplier: null,
	name: null,
};

function RevExpandList(props) {
	const navigate = useNavigate();
	const [revRexpand, setRevRexpand] = useState([]);
	const [paramsList, setParamsList] = useState(initialValues);
	const {emitter} = useMitt();
	const [modal, contextHolder] = Modal.useModal();
	const [loading, setLoading] = useState(false);

	const fetchRevExpandList = (params) => {
		emitter.emit('pendingOn');
		apiGetListRevExpand(params)
			.then((r) => {
				console.log(r);
				setRevRexpand(r);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				emitter.emit('pendingOff');
				setLoading(false);
			});
	};

	const getBillTotal = (data) => {
		console.log('data', data);
		return _.reduce(
			data,
			(sum, item) => {
				console.log('item', item);
				return sum + +item?.price * +item?.quanlity;
			},
			0
		);
	};
	const confirm = (id) => {
		modal.confirm({
			title: 'Xác nhận',
			icon: <ExclamationCircleOutlined />,
			content: 'Bạn chắc chắn muốn xóa !',
			okText: 'Đồng ý',
			onOk: () => {
				console.log('sadfads');
				fetchRemoveRevExpand(id);
			},
		});
	};
	const items = (data) => [
		{
			key: '1',
			label: <RevExpandDetails data={data} />,
		},
		{
			key: '2',
			label: (
				<Link to={`/rev-expand/${data?.id}/update`} rel='noopener noreferrer'>
					Cập nhật
				</Link>
			),
		},

		{
			key: '4',
			danger: true,
			label: (
				<div aria-hidden onClick={() => confirm(data?.id)} style={{width: 200}}>
					Xóa bỏ
				</div>
			),
		},
	];
	const columns = [
		{
			title: '#',

			render: (text, record, index) => (
				<span>
					{index + 1 + (paramsList?.currentPage - 1) * paramsList?.perPage}
				</span>
			),
		},
		{
			title: 'Loại',
			dataIndex: 'type',
			key: 'type',
			render: (text) => {
				return text == 1 ? (
					<div className='rounded-sm text-start text-green-500 ' color='#87d068'>
						Thu vào
					</div>
				) : (
					<div className='rounded-sm text-start text-rose-500  '>Chi ra</div>
				);
			},
		},
		{
			title: 'Tiêu đề',
			dataIndex: 'title',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Thời gian',
			dataIndex: 'date',
			render: (text) => <span>{moment(text).format('DD/MM/YYYY')}</span>,
		},
		// {
		// 	title: 'Số lượng',

		// 	render: (_, record) => (
		// 		<span>
		// 			{record.quanlity}
		// 			<span className='text-gray-300 ml-2'>{record?.unit}</span>
		// 		</span>
		// 	),
		// },
		// {
		// 	title: 'Thành tiền',
		// 	dataIndex: 'value',
		// 	key: 'value',
		// 	render: (text) => <span>{text.toLocaleString('it-IT')}</span>,
		// },

		{
			title: 'Nhà cung cấp',
			dataIndex: 'supplier',
			key: 'supplier',
			render: (text) => <span>{text || 'Không có'}</span>,
		},
		{
			title: 'Tổng tiền',

			render: (_, record) => (
				<>
					{record?.type == 1 && (
						<span className='text-green-500'>
							+
							{new Intl.NumberFormat().format(getBillTotal(record?.revexpanditem)) ||
								'-'}
						</span>
					)}
					{record?.type == 0 && (
						<span className='text-rose-500'>
							-
							{new Intl.NumberFormat().format(getBillTotal(record?.revexpanditem)) ||
								'-'}
						</span>
					)}
				</>
			),
		},
		{
			title: 'Công trình',

			render: (text) => <span>{text?.project?.name}</span>,
		},
		{
			title: 'Người tạo',
			render: (text) => (
				<span className='flex items-center gap-2'>
					<FiUser className='opacity-20' />
					{text?.member?.username}
				</span>
			),
		},
		{
			title: 'Ngày tạo',
			render: (text) => (
				<span>
					{
						<>
							<div>{moment(text?.createdAt).format('HH:mm DD/MM/YYYY')}</div>
							<small className='text-gray-300'>
								{moment(text?.updatedAt).format('HH:mm DD/MM/YYYY')}
							</small>
						</>
					}
				</span>
			),
		},
		{
			title: 'Thao tác',
			key: 'action',

			render: (_, record) => (
				<Dropdown
					menu={{
						items: items(record),
					}}>
					<a
						className='text-sky-600 '
						href='#sda'
						onClick={(e) => e.preventDefault()}>
						<Space>
							<AiOutlineHolder />
						</Space>
					</a>
				</Dropdown>
			),
		},
	];

	const fetchRemoveRevExpand = (id) => {
		apiRemoveRevExpand(id)
			.then((response) => {
				openNotification(NOTIFICATION_TYPE.success, 'Xóa thành công !');
			})
			.then((r) => {
				fetchRevExpandList(paramsList);
			})
			.catch((err) => {
				openNotification(NOTIFICATION_TYPE.error, 'Xóa thất bại !');
				console.log(err);
			});
	};
	useEffect(() => {
		fetchRevExpandList(paramsList);
	}, []);
	return (
		<div className='py-10'>
			{contextHolder}
			<div className='flex justify-between mb-7 px-5'>
				<div className='flex items-center gap-2'>
					<Button
						className='flex justify-center items-center gap-2'
						type='primary'
						onClick={() => {
							navigate('/rev-expand/create');
						}}>
						Tạo mới <FaPlus />
					</Button>
					<Button
						className='flex justify-center items-center gap-2'
						type='primary'
						onClick={() => {
							navigate('/rev-expand-not-price');
						}}>
						Chi tiết <BiDetail />
					</Button>
					{/* <input
						className='flex justify-center items-center gap-2'
						type='file'
						onClick={(e) => {
							const file = event.target.files[0];
							readXlsxFile(file).then((rows) => {
								const newData = rows.map((item) => ({
									bill: {
										type: 0,
										title: item[2],
										projectId: 'nhzuh4da0j',
										date: item[0],
										supplier: item[1],
									},
									items: [
										{
											name: item[2],
											quanlity: item[4] == '-' ? 0 : item[4],
											unit: item[3],
											price: item[5] == '-' ? 0 : item[5],
										},
									],
								}));
								function delay(ms) {
									return new Promise((resolve) => setTimeout(resolve, ms));
								}

								newData.forEach(async (item) => {
									await new Promise((resolve) => setTimeout(resolve, 3000)); // Delay of 300ms
									console.log('sdaf');
									await apiCreateRevExpand(item);
								});
							});
						}}
					/> */}
				</div>
				<div className='flex items-center gap-2'>
					<Input
						value={paramsList?.supplier}
						placeholder='Nhập đối tác'
						style={{width: 160}}
						onChange={(e) => setParamsList({...paramsList, supplier: e.target.value})}
					/>

					<Select
						value={paramsList?.type}
						placeholder={'Loại'}
						onChange={(e) => setParamsList({...paramsList, type: e})}
						style={{
							width: 150,
						}}
						options={[
							{
								value: 0,
								label: 'Chi ra',
							},
							{
								value: 1,
								label: 'Thu vào',
							},
						]}
					/>
					<Input
						value={paramsList?.name}
						placeholder='Nhập tiêu đề'
						style={{width: 160}}
						onChange={(e) => setParamsList({...paramsList, name: e.target.value})}
					/>
					<Button
						onClick={() => {
							setParamsList(initialValues);
						}}
						className=''
						type='default'>
						<GrPowerReset />
					</Button>
					<Button
						loading={loading}
						onClick={() => {
							setLoading(true);
							fetchRevExpandList(paramsList);
						}}
						type='primary'>
						Tìm kiếm
					</Button>
				</div>
			</div>
			<Table columns={columns} dataSource={revRexpand?.data} pagination={false} />
			<div className='flex justify-end mt-5'>
				<Pagination
					pageSize={paramsList.perPage}
					current={paramsList?.currentPage}
					total={revRexpand?.meta?.total}
					onChange={(v) => {
						console.log(v);
						setParamsList({...initialValues, currentPage: v});
						fetchRevExpandList({...initialValues, currentPage: v});
					}}
				/>
			</div>
		</div>
	);
}

RevExpandList.propTypes = {};

export default RevExpandList;
