import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ConfigProvider} from 'antd';
import {CustomRouter, history} from './Routes/CustomerRouter';
import './boostrap';
import 'react-calendar-timeline/lib/Timeline.css';
import {Provider} from 'react-redux';
import {store} from './Store';
import {MittProvider} from 'react-mitt';
import {ErrorBoundary} from './Views/Exception/ErrorBoundary';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<CustomRouter history={history}>
		<Provider store={store}>
			<MittProvider>
				<ErrorBoundary>
					<ConfigProvider
						theme={{
							token: {
								colorPrimary: '#f97316',
								colorPrimaryText: '#f97316',
								colorLink: '#f97316',
								colorLinkHover: '#f97316',
								fontFamily:
									'-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;',
								borderRadius: 5,
							},
						}}>
						<App />
					</ConfigProvider>
				</ErrorBoundary>
			</MittProvider>
		</Provider>
	</CustomRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
