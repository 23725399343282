import {axios} from '../Conf/axios';

export const apiGetListSalary = (params) => axios.get('/salary/list', {params});

export const apiGetListSalarySalary = (params) =>
	axios.get('/salary/salary', {params});

export const apiCreateSalary = (data) => axios.post(`/salary/create`, data);

export const apiUpdateSalary = (id, data) =>
	axios.put(`/salary/${id}/update`, data);

export const apiRemoveSalary = (id) => axios.delete(`/salary/${id}/remove`);

export const apiSalaryDetails = (id) => axios.get(`/salary/${id}/details`);

export const apiCountSalary = () => axios.get(`/salary/count`);
