import {Button, Card, DatePicker, Statistic, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import BaseChartColumn from '../../Components/BaseChartColumn';
import {apiGetRevExpandTotal} from '../../API/revExpandItem';
import _ from 'lodash';
import {apiCountEmployee} from '../../API/employee';
import moment from 'moment';
import {useMitt} from 'react-mitt';
import CountUp from 'react-countup';
import {FiUser} from 'react-icons/fi';
import {apiGetListRevExpand} from '../../API/revExpand';

const formatter = (value) => (
	<CountUp duration={15} end={value} separator=',' />
);
const initialValues = {
	currentPage: 1,
	perPage: 5,
};
function HomeList() {
	const [dataRevenue, setDataRevenue] = useState([]);
	const [dataExpand, setDataExpand] = useState([]);
	const [paramsList, setParamsList] = useState(initialValues);
	const [revRexpand, setRevRexpand] = useState([]);
	const fetchRevExpandList = (params) => {
		emitter.emit('pendingOn');
		apiGetListRevExpand(params)
			.then((r) => {
				console.log(r);
				setRevRexpand(r);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				emitter.emit('pendingOff');
				// setLoading(false);
			});
	};

	const [countEmployee, setCountEmployee] = useState(0);
	const {emitter} = useMitt();
	const getBillTotal = (data) => {
		console.log('data', data);
		return _.reduce(
			data,
			(sum, item) => {
				console.log('item', item);
				return sum + +item?.price * +item?.quanlity;
			},
			0
		);
	};
	const fetchRevExpandTotalByMonth = () => {
		const startOfYear = moment().add(-10, 'days');
		const endOfYear = moment().add(1, 'days');

		const data = {
			start: startOfYear.toISOString(),
			end: endOfYear.toISOString(),
		};
		apiGetRevExpandTotal(data)
			.then((r) => {
				const currentDate = moment().add(-9, 'days');
				const startOfWeek = currentDate.clone();
				const daysOfWeek = [];
				for (let i = 0; i < 10; i++) {
					daysOfWeek.push(startOfWeek.clone().add(i, 'days').format('DD'));
				}
				const revnue = daysOfWeek.map((item) => {
					const find = _.find(r, (o) => o?.day == item && o?.type == 1);
					if (find) {
						return {
							value: find?.total,
							category: item,
						};
					} else {
						return {
							value: 0,
							category: item,
						};
					}
				});
				const expand = daysOfWeek.map((item) => {
					const find = _.find(r, (o) => o?.day == item && o?.type == 0);
					if (find) {
						return {
							value: find?.total,
							category: item,
						};
					} else {
						return {
							value: 0,
							category: item,
						};
					}
				});

				setDataRevenue(revnue);
				setDataExpand(expand);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const fetchCountEmployeeActive = () => {
		apiCountEmployee()
			.then((r) => {
				setCountEmployee(r);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const columns = [
		{
			title: '#',

			render: (text, record, index) => (
				<span>
					{index + 1 + (paramsList?.currentPage - 1) * paramsList?.perPage}
				</span>
			),
		},
		{
			title: 'Loại',
			dataIndex: 'type',
			key: 'type',
			render: (text) => {
				return text == 1 ? (
					<div className='rounded-sm text-start text-green-500 ' color='#87d068'>
						Thu vào
					</div>
				) : (
					<div className='rounded-sm text-start text-rose-500  '>Chi ra</div>
				);
			},
		},
		{
			title: 'Tiêu đề',
			dataIndex: 'title',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Thời gian',
			dataIndex: 'date',
			render: (text) => <span>{moment(text).format('DD/MM/YYYY')}</span>,
		},
		// {
		// 	title: 'Số lượng',

		// 	render: (_, record) => (
		// 		<span>
		// 			{record.quanlity}
		// 			<span className='text-gray-300 ml-2'>{record?.unit}</span>
		// 		</span>
		// 	),
		// },
		// {
		// 	title: 'Thành tiền',
		// 	dataIndex: 'value',
		// 	key: 'value',
		// 	render: (text) => <span>{text.toLocaleString('it-IT')}</span>,
		// },

		{
			title: 'Nhà cung cấp',
			dataIndex: 'supplier',
			key: 'supplier',
			render: (text) => <span>{text || 'Không có'}</span>,
		},
		{
			title: 'Tổng tiền',

			render: (_, record) => (
				<>
					{record?.type == 1 && (
						<span className='text-green-500'>
							+
							{new Intl.NumberFormat().format(getBillTotal(record?.revexpanditem)) ||
								'-'}
						</span>
					)}
					{record?.type == 0 && (
						<span className='text-rose-500'>
							-
							{new Intl.NumberFormat().format(getBillTotal(record?.revexpanditem)) ||
								'-'}
						</span>
					)}
				</>
			),
		},
		{
			title: 'Công trình',

			render: (text) => <span>{text?.project?.name}</span>,
		},
		{
			title: 'Người tạo',
			render: (text) => (
				<span className='flex items-center gap-2'>
					<FiUser className='opacity-20' />
					{text?.member?.username}
				</span>
			),
		},
		{
			title: 'Ngày tạo',
			render: (text) => (
				<span>
					{
						<>
							<div>{moment(text?.createdAt).format('HH:mm DD/MM/YYYY')}</div>
							<small className='text-gray-300'>
								{moment(text?.updatedAt).format('HH:mm DD/MM/YYYY')}
							</small>
						</>
					}
				</span>
			),
		},
	];

	useEffect(() => {
		emitter.emit('pendingOn');
		Promise.all([
			fetchRevExpandTotalByMonth(),
			fetchCountEmployeeActive(),
			fetchRevExpandList(paramsList),
		]).finally(() => {
			emitter.emit('pendingOff');
		});
	}, []);

	return (
		<>
			<div className=' px-5 mt-9 text-4xl font-extrabold opacity-5 '>
				Thống kê 10 (ngày) gần nhất
			</div>
			{/* <div className='p-5  mt-5  flex justify-between items-center'>
				<div className='flex gap-4 items-center'>
					Bắt đầu :
					<DatePicker placeholder='Bắt đầu' />
					Kết thúc :
					<DatePicker placeholder='Kết thúc' />
				</div>
				<div>
					<Button type='primary'>Xác nhận</Button>
				</div>
			</div> */}
			<div className='p-10 grid grid-cols-4 gap-7 '>
				<Card bordered={false}>
					<Statistic
						title='TỔNG THU'
						prefix='$'
						value={_.sum(_.map(dataRevenue, (item) => Math.round(item?.value)))}
						valueStyle={{
							fontWeight: 800,
							color: '#f97316',
							fontFamily: 'monospace',
							fontSize: 25,
						}}
						formatter={formatter}
					/>
				</Card>
				<Card bordered={false}>
					<Statistic
						title='TỔNG CHI'
						prefix='$'
						formatter={formatter}
						value={_.sum(_.map(dataExpand, (item) => Math.round(item?.value)))}
						valueStyle={{
							fontWeight: 800,
							color: '#f97316',
							fontFamily: 'monospace',
							fontSize: 25,
						}}
					/>
				</Card>
				<Card bordered={false}>
					<Statistic
						title='LỢI NHUẬN'
						prefix='$'
						formatter={formatter}
						value={
							_.sum(_.map(dataRevenue, (item) => Math.round(item?.value))) -
							_.sum(_.map(dataExpand, (item) => Math.round(item?.value)))
						}
						valueStyle={{
							fontWeight: 800,
							color: '#f97316',
							fontFamily: 'monospace',
							fontSize: 25,
						}}
					/>
				</Card>
				<Card bordered={false}>
					<Statistic
						title='NHÂN CÔNG'
						formatter={formatter}
						value={countEmployee}
						valueStyle={{
							fontWeight: 800,
							color: '#f97316',
							fontFamily: 'monospace',
							fontSize: 25,
						}}
					/>
				</Card>
			</div>
			<div className='grid grid-cols-2 mx-10 gap-7'>
				<div className='bg-white  col-span-1 rounded-md shadow-sm'>
					<BaseChartColumn
						data={_.map(dataExpand, (item) => Math.round(item?.value / 1000000))}
						categories={_.map(
							dataExpand,
							(item) => `${item?.category}/${Number(moment().month()) + 1}`
						)}
					/>
					<div className='text-center mb-4 font-light'>
						BIỂU ĐỒ TỔNG CHI TRONG TUẦN (TRIỆU)
					</div>
				</div>
				<div className='bg-white  col-span-1 rounded-md shadow-sm'>
					<BaseChartColumn
						data={_.map(dataRevenue, (item) => Math.round(item?.value / 1000000))}
						categories={_.map(
							dataRevenue,
							(item) => `${item?.category}/${Number(moment().month()) + 1}`
						)}
					/>
					<div className='text-center mb-4 font-light'>
						BIỂU ĐỒ TỔNG THU TRONG TUẦN (TRIỆU)
					</div>
				</div>
			</div>
			<div className='p-10'>
				<Table columns={columns} dataSource={revRexpand?.data} pagination={false} />
			</div>{' '}
		</>
	);
}

export default HomeList;
