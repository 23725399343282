import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {IoCloseCircleOutline} from 'react-icons/io5';

import {
	Alert,
	AutoComplete,
	Button,
	DatePicker,
	Divider,
	Form,
	Input,
	InputNumber,
	Select,
	Switch,
} from 'antd';
import {apiGetListProject} from '../../API/project';
import {apiGetListEmployee} from '../../API/employee';
import _ from 'lodash';
import {apiCreateTimeWork} from '../../API/timeKeeping';
import {getMessageErrors, optionValidate} from '../../Helper/vailate';
import {openNotification} from '../../Helper/notification';
import {NOTIFICATION_TYPE} from '../../Contant/global';
import {useNavigate} from 'react-router-dom';
import {apiCreateTimeWorkDetail} from '../../API/timeWorkDetails';
import {useMitt} from 'react-mitt';
const mockVal = (str, repeat = 1) => ({
	value: str.repeat(repeat),
});
function TimeKeepingCreate(props) {
	const [value, setValue] = useState('');
	const [form] = Form.useForm();
	const [options, setOptions] = useState([]);
	const [projects, setProjects] = useState([]);
	const [employeeList, setEmployeeList] = useState([]);
	const [errors, setErrors] = useState([]);
	const navigate = useNavigate();
	const {emitter} = useMitt();
	const [loading, setLoading] = useState(false);

	const autoCompleteRef = useRef();
	const onSelect = (data) => {
		const find = _.find(options, (item) => item?.value == data);
		console.log('find', find, options);

		setEmployeeList([...employeeList, {label: find?.label, value: find?.value}]);

		autoCompleteRef.current.value = null;
	};

	const fetchProjectList = () => {
		const params = {
			currentPage: 1,
			perPage: 1000,
			status: 1,
		};
		apiGetListProject(params)
			.then((r) => {
				console.log(r);
				setProjects(r);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const fetchEmployeeList = (name) => {
		const params = {
			currentPage: 1,
			perPage: 1000,
			fullName: name,
			status: 0,
		};
		apiGetListEmployee(params)
			.then((r) => {
				console.log('r', r);
				setOptions(
					r?.data?.map((item) => ({
						value: item?.id,
						label: item?.fullName,
						salaryId: item?.salary?.id,
					}))
				);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const onFinish = () => {
		emitter.emit('pendingOn');
		setLoading(true);
		const data = _.map(employeeList, (item) => ({
			projectId: form.getFieldValue('projectId'),
			employeeId: item?.value,
			date: new Date(form.getFieldValue('date')).toISOString(),
			time: form.getFieldValue('time'),
			type: form.getFieldValue('type'),
		}));
		apiCreateTimeWorkDetail(data)
			.then((response) => {
				openNotification(NOTIFICATION_TYPE.success, 'Tạo mới thành công !');
			})
			.then((response) => {
				navigate('/time-keeping');
			})
			.catch((err) => {
				openNotification(NOTIFICATION_TYPE.error, 'Tạo mới thất bại !');
				console.log(err);
			})
			.finally((r) => {
				emitter.emit('pendingOff');
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchProjectList();
	}, []);
	return (
		<Form
			form={form}
			onFinish={onFinish}
			onFinishFailed={() => {
				setErrors(getMessageErrors(form.getFieldsError()));
			}}
			className='p-10 flex flex-col gap-4 '
			style={{width: 350}}>
			<div>
				<div className='mb-2'>Công trình : </div>
				{/* <label
					htmlFor='password'
					className='block mb-2   text-gray-900 dark:text-white mt-3'>
					Công trình
				</label> */}
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('projectId') ? '' : 'error'}
					rules={optionValidate(true, null, null, null, null, 'Công trình')}
					name={'projectId'}>
					<Select
						placeholder='Công trình'
						className='w-full'
						options={projects?.data?.map((item) => ({
							value: item?.id,
							label: item?.name,
						}))}
					/>
				</Form.Item>
			</div>
			<div>
				<div className='mb-2'>Ngày chấm : </div>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('date') ? '' : 'error'}
					rules={optionValidate(true, null, null, null, null, 'Ngày chấm')}
					name={'date'}>
					<DatePicker className='w-full' />
				</Form.Item>
			</div>

			<div>
				<div className='mb-2 '>Số giờ : </div>
				<Form.Item
					noStyle={true}
					validateStatus={form.getFieldError('time') ? '' : 'error'}
					rules={optionValidate(true, 1, 10000000, null, null, 'Số giờ')}
					name={'time'}>
					{' '}
					<InputNumber
						className='w-full'
						placeholder='8'
						onChange={(e) => {
							form.setFieldValue('time', e);
						}}
					/>
				</Form.Item>
			</div>
			<div>
				<div className='mb-2 '>Loại : </div>
				<div className=''>
					<Form.Item
						name={'type'}
						noStyle={true}
						validateStatus={form.getFieldError('type') ? '' : 'error'}
						rules={optionValidate(true, null, null, null, null, 'Loại')}>
						<Select
							placeholder='Vui lòng chọn'
							className='w-full'
							options={[
								{
									value: 0,
									label: 'Công nhật',
								},
								{
									value: 1,
									label: 'Tăng ca',
								},
							]}
						/>
					</Form.Item>
				</div>
			</div>
			<div>
				<div className='mb-2'>Nhân công : </div>

				<AutoComplete
					ref={autoCompleteRef}
					options={options}
					className='w-full'
					onSelect={onSelect}
					onSearch={(text) => {
						fetchEmployeeList(text);
					}}
					value={null}
					placeholder='Nhân công'
				/>
			</div>
			{errors.length > 0 && (
				<Alert
					className='rounded mt-5'
					message={
						<div className='flex flex-col text-gray-500' style={{fontSize: 13}}>
							{errors?.map((o, i) => (
								<div key={i}>{o}</div>
							))}
						</div>
					}
					type='warning'
				/>
			)}
			<Divider className='my-2' />

			<div className='flex flex-col gap-3'>
				{employeeList?.map((item, index) => (
					<div
						key={item?.id}
						className='border p-2 bg-white rounded flex justify-between items-center'>
						<div>
							{index + 1}. {item?.label}
						</div>{' '}
						<div>
							<IoCloseCircleOutline
								onClick={() => {
									const newListEmployee = _.filter(
										employeeList,
										(item, idx) => idx != index
									);
									setEmployeeList(newListEmployee);
								}}
							/>
						</div>
					</div>
				))}

				<div>
					<Button
						loading={loading}
						htmlType='submit'
						className='w-full mt-2'
						type='primary'>
						Chấm công
					</Button>
				</div>
			</div>
		</Form>
	);
}

TimeKeepingCreate.propTypes = {};

export default TimeKeepingCreate;
