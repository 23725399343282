import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {DownOutlined, SmileOutlined} from '@ant-design/icons';
import {Dropdown, Modal} from 'antd';
import {Button, Input, Pagination, Space, Table, Tag} from 'antd';
import {Link, useNavigate} from 'react-router-dom';

import {AiOutlineHolder} from 'react-icons/ai';

import moment from 'moment';
import EquipmentListDetail from './EquipmentDetails';
import {openNotification} from '../../Helper/notification';
import {NOTIFICATION_TYPE} from '../../Contant/global';
import {useMitt} from 'react-mitt';
import {GrPowerReset} from 'react-icons/gr';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {apiGetListEquipment, apiRemoveEquipment} from '../../API/equipment';

const initialValues = {
	currentPage: 1,
	perPage: 10,
};

function EquipmentList(props) {
	const navigate = useNavigate();
	const [equipments, setEquipmentLists] = useState([]);
	const [paramsList, setParamsList] = useState(initialValues);
	const {emitter} = useMitt();
	const [modal, contextHolder] = Modal.useModal();

	const items = (data) => [
		{
			key: '1',
			label: <EquipmentListDetail data={data} />,
		},
		{
			key: '2',
			label: (
				<Link to={`/equipment/${data?.id}/update`} rel='noopener noreferrer'>
					Cập nhật
				</Link>
			),
		},

		{
			key: '4',
			danger: true,

			label: (
				<div aria-hidden onClick={() => confirm(data?.id)} style={{width: 200}}>
					Xóa bỏ
				</div>
			),
		},
	];
	const columns = [
		{
			title: '#',

			render: (text, record, index) => (
				<a href='/#'>
					{index + 1 + (paramsList?.currentPage - 1) * paramsList?.perPage}
				</a>
			),
		},
		{
			title: 'Mã thiết bị',
			dataIndex: 'code',
			key: 'code',
		},

		{
			title: 'Tên vật tư',
			dataIndex: 'name',
			key: 'name',
			render: (_, record) => (
				<div className='flex flex-col'>
					<a href='/#'>{record?.name}</a>
					<small className='text-gray-400'>{record?.serial || 'No serial'}</small>
				</div>
			),
		},
		{
			title: 'Cung cấp',
			dataIndex: 'prodcucer',
			key: 'prodcucer',
		},
		{
			title: 'Số lượng',
			dataIndex: 'quanlity',
			key: 'quanlity',
		},
		{
			title: 'Đơn vị',
			dataIndex: 'unit',
			key: 'unit',
		},

		{
			title: 'Ngày tạo',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (_, record) => (
				<>
					<div>{moment(record?.createdAt).format('hh:mm DD/MM/YYYY')}</div>
					<small className='text-gray-300'>
						{moment(record?.updatedAt).format('hh:mm DD/MM/YYYY')}
					</small>
				</>
			),
		},
		{
			title: 'Thao tác',
			key: 'action',

			render: (_, record) => (
				<Dropdown
					className='w-48'
					menu={{
						items: items(record),
					}}>
					<a
						className='text-sky-600 '
						href='#sda'
						onClick={(e) => e.preventDefault()}>
						<Space>
							<AiOutlineHolder />
						</Space>
					</a>
				</Dropdown>
			),
		},
	];
	const confirm = (id) => {
		modal.confirm({
			title: 'Xác nhận',
			icon: <ExclamationCircleOutlined />,
			content: 'Bạn chắc chắn muốn xóa !',
			okText: 'Đồng ý',
			onOk: () => {
				console.log('sadfads');
				fetchRemoveEquipmentList(id);
			},
		});
	};
	const fetchEquipmentListList = (params) => {
		emitter.emit('pendingOn');
		apiGetListEquipment(params)
			.then((r) => {
				console.log(r);
				setEquipmentLists(r);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				emitter.emit('pendingOff');
			});
	};

	const fetchRemoveEquipmentList = (id) => {
		apiRemoveEquipment(id)
			.then((response) => {
				openNotification(NOTIFICATION_TYPE.success, 'Xóa thành công !');
			})
			.then((r) => {
				fetchEquipmentListList(paramsList);
			})
			.catch((err) => {
				openNotification(NOTIFICATION_TYPE.error, 'Xóa thất bại !');
				console.log(err);
			});
	};
	useEffect(() => {
		fetchEquipmentListList(paramsList);
	}, []);
	return (
		<div className='py-10'>
			{contextHolder}
			<div className='flex justify-between mb-7 px-5'>
				<div>
					<Button
						type='primary'
						onClick={() => {
							navigate('/equipment/create');
						}}>
						Tạo mới{' '}
					</Button>
				</div>
				<div className='flex items-center gap-2'>
					<Input
						placeholder='Tên thiết bị'
						onChange={(e) => {
							setParamsList({...paramsList, name: e.target.value});
						}}
						style={{width: 200}}
					/>
					<Button
						onClick={() => {
							setParamsList(initialValues);
						}}
						className=''
						type='default'>
						<GrPowerReset />
					</Button>
					<Button
						type='primary'
						onClick={() => {
							fetchEquipmentListList(paramsList);
						}}>
						Tìm kiếm
					</Button>
				</div>
			</div>
			<Table columns={columns} dataSource={equipments?.data} pagination={false} />
			<div className='flex justify-end mt-5'>
				<Pagination
					pageSize={paramsList.perPage}
					current={paramsList?.currentPage}
					total={equipments?.meta?.total}
					onChange={(v) => {
						setParamsList({...initialValues, currentPage: v});
						fetchEquipmentListList({...initialValues, currentPage: v});
					}}
				/>
			</div>
		</div>
	);
}

EquipmentList.propTypes = {};

export default EquipmentList;
