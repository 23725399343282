import {Button, DatePicker, Input, Pagination, Select, Table} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as XLSX from 'xlsx';

import {apiGetListSalaryEmployee} from '../../API/employee';
import TimeWorkDetails from './TimeWorkDetails';
import {useMitt} from 'react-mitt';
import {GrPowerReset} from 'react-icons/gr';
import {apiGetListProject} from '../../API/project';
import moment from 'moment';
import dayjs from 'dayjs';
import {TfiExport} from 'react-icons/tfi';
import {CSVLink, CSVDownload} from 'react-csv';
import {saveAs} from 'file-saver';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import _ from 'lodash';
import BaseExportExcel from '../../Components/BaseExportExcel';
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';

const prettyLink = {
	backgroundColor: '#8dc63f',
	fontSize: 14,
	fontWeight: 500,
	height: 52,
	padding: '0 48px',
	borderRadius: 5,
	color: '#fff',
};

const initialValues = {
	currentPage: 1,
	perPage: 10,
	projectId: null,
	start: moment().startOf('month').format('YYYY-MM-DD'),
	end: moment().endOf('month').format('YYYY-MM-DD'),
	employeeName: null,
};

function TimeWorkList(props) {
	const navigate = useNavigate();
	const {emitter} = useMitt();
	const [projects, setProjects] = useState([]);
	const [loading, setLoading] = useState(false);
	const [employees, setEmployees] = useState([]);
	const [paramsList, setParamsList] = useState(initialValues);
	const fetchEmployeeSalaryList = (params) => {
		emitter.emit('pendingOn');

		apiGetListSalaryEmployee(params)
			.then((r) => {
				console.log(r);
				setEmployees(r);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				emitter.emit('pendingOff');
				setLoading(false);
			});
	};
	const exportData = () => {
		emitter.emit('pendingOn');
		const data = {
			...paramsList,
			perPage: 1000,
		};
		paramsList?.start != null
			? (data.start = new Date(paramsList?.start).toISOString())
			: null;
		paramsList?.end != null
			? (data.end = new Date(paramsList?.end).toISOString())
			: null;
		apiGetListSalaryEmployee(data)
			.then((r) => {
				const newData = _.map(r?.data, (item, index) => {
					return {
						fullName: item?.fullName,
						workday:
							countWorkday(item?.timeWorkDetail, 0) / 8 == 0
								? '-'
								: countWorkday(item?.timeWorkDetail, 0) / 8,
						workdayAdd:
							countWorkday(item?.timeWorkDetail, 1) == 0
								? '-'
								: countWorkday(item?.timeWorkDetail, 1),
						moneyWorkday:
							countWorkday(item?.timeWorkDetail, 0) == 0
								? '-'
								: new Intl.NumberFormat().format(
										(countWorkday(item?.timeWorkDetail, 0) / 8) * +item?.salary?.wage
									),
						moneyWorkdayAdd:
							countWorkday(item?.timeWorkDetail, 1) == 0
								? '-'
								: new Intl.NumberFormat().format(
										countWorkday(item?.timeWorkDetail, 1) * +item?.salary?.wageOvertime
									),
						total:
							countWorkday(item?.timeWorkDetail, 0) == 0 &&
							countWorkday(item?.timeWorkDetail, 1) == 0
								? '-'
								: new Intl.NumberFormat().format(
										(countWorkday(item?.timeWorkDetail, 0) / 8) * +item?.salary?.wage +
											countWorkday(item?.timeWorkDetail, 1) * +item?.salary?.wageOvertime
									),
					};
				});
				const headers = [
					'STT',
					'Họ Và Tên',
					'Ngày Công',
					'Tăng Ca',
					'Tiền Công',
					'Tiền TC',
					'Tổng Tiền',
				];
				// Create a worksheet from the data
				const worksheetData = [
					headers,
					...newData.map((item, index) => [
						index + 1,
						item.fullName,
						item.workday,
						item.workdayAdd,
						item.moneyWorkday,
						item.moneyWorkday,
						item.total,
					]),
				];
				const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
				// Create a new workbook and append the worksheet
				const workbook = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(workbook, worksheet, 'Tiền Công');
				// Write the workbook to a binary string
				const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
				// Create a Blob object from the binary string and save it as an Excel file
				const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
				saveAs(blob, 'tien-cong.xlsx');
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				emitter.emit('pendingOff');
				setLoading(false);
			});
	};
	const countWorkday = (list, type) => {
		return _.sumBy(list, (detail) => {
			if (detail.type == type) {
				return detail.time;
			}
			return 0;
		});
	};

	const columns = [
		{
			title: '#',
			dataIndex: 'id',
			key: 'id',
			render: (text, record, index) => (
				<span>
					{index + 1 + (paramsList?.currentPage - 1) * paramsList?.perPage}
				</span>
			),
		},

		{
			title: 'Họ tên',
			dataIndex: 'fullName',
			key: 'fullName',
			render: (_, record) => (
				<div className='flex flex-col'>
					<span>{record?.fullName}</span>
					<small className='text-gray-400'>{record?.idCard}</small>
				</div>
			),
		},

		// {
		// 	title: 'Công trình',
		// 	dataIndex: 'project',
		// 	key: 'project',
		// 	render: (_, record) => (
		// 		<div className='flex flex-col'>
		// 			<span>Tất cả</span>
		// 		</div>
		// 	),
		// },
		{
			title: 'Ngày công',

			render: (_, record) => (
				<span>
					{countWorkday(record?.timeWorkDetail, 0) / 8 == 0
						? '-'
						: countWorkday(record?.timeWorkDetail, 0) / 8}{' '}
					<small className='text-gray-300'>(công)</small>
				</span>
			),
		},
		{
			title: 'Tăng ca',

			render: (_, record) => (
				<span>
					{countWorkday(record?.timeWorkDetail, 1) == 0
						? '-'
						: countWorkday(record?.timeWorkDetail, 1)}{' '}
					<small className='text-gray-300'>(h)</small>
				</span>
			),
		},
		{
			title: 'Tiền Công',

			render: (_, record) => (
				// <span>
				// 	{countWorkday(record?.timeWork) * +record?.salary?.wage == 0
				// 		? '-'
				// 		: new Intl.NumberFormat().format(countWorkday(record?.timeWork))}
				// </span>
				<span>
					{countWorkday(record?.timeWorkDetail, 0) == 0
						? '-'
						: new Intl.NumberFormat().format(
								(countWorkday(record?.timeWorkDetail, 0) / 8) * +record?.salary?.wage
							)}
				</span>
			),
		},
		{
			title: 'Tiền Tăng Ca',

			render: (_, record) => (
				<span>
					{countWorkday(record?.timeWorkDetail, 1) == 0
						? '-'
						: new Intl.NumberFormat().format(
								countWorkday(record?.timeWorkDetail, 1) * +record?.salary?.wageOvertime
							)}
				</span>
			),
		},
		{
			title: 'Tổng Tiền',
			dataIndex: 'address',
			key: 'address',
			render: (_, record) => (
				<span>
					{countWorkday(record?.timeWorkDetail, 0) == 0 &&
					countWorkday(record?.timeWorkDetail, 1) == 0
						? '-'
						: new Intl.NumberFormat().format(
								(countWorkday(record?.timeWorkDetail, 0) / 8) * +record?.salary?.wage +
									countWorkday(record?.timeWorkDetail, 1) * +record?.salary?.wageOvertime
							)}
				</span>
			),
		},

		{
			title: 'Thao tác',
			key: 'action',

			render: (_, record) => <TimeWorkDetails data={record} />,
		},
	];
	const fetchProjectList = () => {
		const params = {
			currentPage: 1,
			perPage: 1000,
			type: 1,
		};
		apiGetListProject(params)
			.then((r) => {
				console.log(r);
				setProjects(r);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	useEffect(() => {
		fetchEmployeeSalaryList(paramsList);
		fetchProjectList();
	}, []);
	return (
		<div className='py-10'>
			<div className='flex justify-between mb-7 px-5'>
				<div>
					{/* <Button
						type='link'
						onClick={() => {
							navigate('/employee/create');
						}}>
						Tạo mới{' '}
					</Button> */}
				</div>
				<div className='flex items-center gap-2'>
					<DatePicker
						defaultValue={dayjs(paramsList.start, dateFormat)}
						format={dateFormat}
						onChange={(e) => setParamsList({...paramsList, start: e})}
						placeholder='Bắt đầu'
					/>
					<DatePicker
						defaultValue={dayjs(paramsList.end, dateFormat)}
						format={dateFormat}
						onChange={(e) => setParamsList({...paramsList, end: e})}
						placeholder='Kết thúc'
					/>
					<Select
						value={paramsList?.projectId}
						style={{width: 150}}
						onChange={(e) => setParamsList({...paramsList, projectId: e})}
						placeholder='Công trình'
						className='w-full'
						options={projects?.data?.map((item) => ({
							value: item?.id,
							label: item?.name,
						}))}
					/>
					<Input
						value={paramsList?.employeeName}
						onChange={(e) =>
							setParamsList({...paramsList, employeeName: e.target.value})
						}
						placeholder='Công nhân'
						style={{width: 150}}
					/>
					<Button
						onClick={() => {
							setParamsList(initialValues);
						}}
						className=''
						type='default'>
						<GrPowerReset />
					</Button>
					<Button
						loading={loading}
						onClick={() => {
							setLoading(true);

							const data = {
								...paramsList,
							};
							paramsList?.start != null
								? (data.start = new Date(paramsList?.start).toISOString())
								: null;
							paramsList?.end != null
								? (data.end = new Date(paramsList?.end).toISOString())
								: null;

							fetchEmployeeSalaryList(data);
						}}
						type='primary'>
						Tìm kiếm
					</Button>
					<Button type='primary' onClick={exportData}>
						Export
					</Button>
				</div>
			</div>
			<Table columns={columns} dataSource={employees?.data} pagination={false} />
			<div className='flex justify-end mt-5'>
				<Pagination
					pageSize={paramsList.perPage}
					current={paramsList?.currentPage}
					total={employees?.meta?.total}
					onChange={(v) => {
						const data = {
							...paramsList,
						};
						paramsList?.start != null
							? (data.start = new Date(paramsList?.start).toISOString())
							: null;
						paramsList?.end != null
							? (data.end = new Date(paramsList?.end).toISOString())
							: null;
						setParamsList({...data, currentPage: v});
						fetchEmployeeSalaryList({...data, currentPage: v});
					}}
				/>
			</div>
		</div>
	);
}

TimeWorkList.propTypes = {};

export default TimeWorkList;
