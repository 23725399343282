export const optionValidate = (
	required,
	min,
	max,
	minLength,
	maxLength,
	key
) => {
	let rules = [];
	if (required)
		rules.push({
			required: true,
			message: `Vui lòng nhập ${String(key).toLocaleLowerCase()}. `,
		});
	if (min)
		rules.push({
			type: 'number',
			min: min,
			message: `Giá trị ${String(key).toLocaleLowerCase()}  nhỏ nhất ${min}`,
		});
	if (max)
		rules.push({
			type: 'number',
			max: max,
			message: ` Giá trị ${String(key).toLocaleLowerCase()} lớn nhất ${max}`,
		});
	if (minLength)
		rules.push({
			min: minLength,
			message: ` Độ dài ${String(key).toLocaleLowerCase()} tối thiểu ${minLength} ký tự.`,
		});
	if (maxLength)
		rules.push({
			max: maxLength,
			message: ` Độ dài tối đa ${maxLength} ký tự.`,
		});
	return rules;
};

export const getMessageErrors = (data) => {
	return data?.reduce((accumulator, item) => {
		// Thêm tất cả các lỗi của mỗi mục vào mảng tổng
		accumulator.push(...item.errors);
		return accumulator;
	}, []);
};

export const validateConfirmPassword = ({getFieldValue}) => ({
	validator(_, value) {
		if (!value || getFieldValue('newPassword') == value) {
			return Promise.resolve();
		}
		return Promise.reject(`Mật khẩu không khớp.`);
	},
});

export const validatePassword = (_, value) => {
	// Define your custom password pattern using a regular expression
	const passwordPattern =
		/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

	if (value && !passwordPattern.test(value)) {
		return Promise.reject(
			'Mật khẩu phải có ít nhất 8 ký tự và chứa ít nhất một chữ cái viết hoa, một chữ cái viết thường, một chữ số và một ký tự đặc biệt.'
		);
	}

	return Promise.resolve();
};
export const validateEmail = (_, value) => {
	// Define a basic email validation regular expression
	const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	if (value && !emailPattern.test(value)) {
		return Promise.reject('Không phải định dạng mail.');
	}

	return Promise.resolve();
};
