import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import BaseInput from '../../Components/BaseInput';
import {apiRevExpandDetails, apiUpdateRevExpand} from '../../API/revExpand';
// import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {optionValidate} from '../../Helper/vailate';
import {Button, Divider, Input} from 'antd';
import BaseSelectEdit from '../../Components/BaseSelectEdit';
import {MdModeEditOutline} from 'react-icons/md';

function RevExpandUpdate(props) {
	const {id} = useParams();
	const navigate = useNavigate();
	const [revExpandDetails, setRevExpandDetails] = useState();
	// const dispatch = useDispatch();
	const getRevExpandDetails = (id) => {
		apiRevExpandDetails(id)
			.then((result) => {
				setRevExpandDetails(result);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(() => {
		if (id) {
			getRevExpandDetails(id);
		}
	}, [id]);

	return (
		<div style={{width: '100%'}}>
			<div className='w-full justify-between '>
				<div className='w-5/12 p-5 ml-5 mt-5'>
					<BaseInput
						apiUpdate={apiUpdateRevExpand}
						edit={false}
						field={'projectId'}
						id={id}
						label={'Công trình'}
						value={revExpandDetails?.project?.name}
					/>
					<Divider />
					<BaseInput
						apiUpdate={apiUpdateRevExpand}
						edit={false}
						field={'date'}
						id={id}
						label={'Ngày nhập'}
						value={revExpandDetails?.date}
						rules={optionValidate('date', true, null, null, 5, 30)}
					/>
					<Divider />

					<BaseSelectEdit
						apiUpdate={apiUpdateRevExpand}
						id={id}
						edit={true}
						label={'Loại'}
						field={'type'}
						option={[
							{label: 'Chi ra ', value: 0},
							{label: 'Thu vào', value: 1},
						]}
						value={revExpandDetails?.type}
						rules={optionValidate(true, null, null, null, null, 'Loại')}
					/>
					<Divider />
					<BaseInput
						apiUpdate={apiUpdateRevExpand}
						edit={true}
						field={'title'}
						id={id}
						label={'Tiêu đề'}
						value={revExpandDetails?.title}
						rules={optionValidate(true, null, null, 1, 100, 'Tiêu đề')}
					/>
					<Divider />

					<BaseInput
						apiUpdate={apiUpdateRevExpand}
						edit={true}
						field={'supplier'}
						id={id}
						label={'Đối tác'}
						value={revExpandDetails?.supplier}
						rules={optionValidate(true, null, null, 1, 100, 'Đối tác')}
					/>

					{revExpandDetails?.revexpanditem?.map((item, index) => (
						<>
							<Divider />
							<div className='grid grid-cols-12 items-center'>
								<div className='col-span-3'>{index + 1} .</div>
								<div className='col-span-6'>
									<Input value={item?.name} />
								</div>
								<div className='col-span-3 flex justify-end'>
									<Button
										onClick={() => {
											navigate(`/rev-expand-item/${item?.id}/update`);
										}}
										type='link'>
										<MdModeEditOutline />
									</Button>
								</div>
							</div>
						</>
					))}
				</div>
			</div>{' '}
		</div>
	);
}

RevExpandUpdate.propTypes = {};

export default RevExpandUpdate;
