import {Col, Divider, Drawer, QRCode, Row} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

EquipmentDetails.propTypes = {
	data: PropTypes.object,
};
const DescriptionItem = ({title, content}) => (
	<div className='site-description-item-profile-wrapper'>
		<p className='site-description-item-profile-p-label'>{title}:</p>
		{content}
	</div>
);

DescriptionItem.propTypes = {
	title: PropTypes.string,
	content: PropTypes.any,
};

export default function EquipmentDetails({data}) {
	const [open, setOpen] = useState(false);
	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		console.log(data);
	}, []);

	return (
		<>
			<button onClick={showDrawer}>Chi tiết</button>
			<Drawer
				width={340}
				placement='right'
				closable={false}
				onClose={onClose}
				open={open}>
				<div className='flex items-center justify-between mb-6 gap-5'>
					<p className='site-description-item-profile-p font-semibold text-xl my-0'>
						<QRCode size={100} value={data?.id || '-'} />
					</p>
					{/* <div className='flex items-center gap-3 bg-gray-100 p-2 py-1 rounded'>
						<Link to={`/phong/${data?.id}/cap-nhat`}>
							<FiEdit2 color='#3b82f6' size={17} />
						</Link>
						<button>
							<AiOutlineDelete color='red' size={17} />
						</button>
					</div> */}
				</div>
				<Row>
					<Col span={24}>
						<DescriptionItem title='Tên ' content={data?.name} />
					</Col>
					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem title='Mã vật tư' content={data?.code} />
					</Col>
					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem title='Serial' content={data?.serial} />
					</Col>
					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem title='Nhà cung cấp' content={data?.prodcucer} />
					</Col>
					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem title='Đơn vị' content={data?.unit} />
					</Col>
					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem title='Số lượng' content={data?.quanlity} />
					</Col>
					<Divider className='my-3' />
					<Col span={24}>
						<DescriptionItem
							title='Ngày tạo'
							content={moment(data?.createdAt).format('DD/MM/YYYY')}
						/>
					</Col>{' '}
					<Divider className='my-3' />
					{/* <Col span={24}>
						<DescriptionItem title='Ngày làm' content={data?.hireDate} />
					</Col> */}
					{/* <Col span={24}>
						{data?.status == EMPLOYEE_STATUS.Actived && (
							<DescriptionItem
								title='Trạng thái'
								content={
									<span className='ml-2 items-center text-sky-500'>
										<Badge color='rgb(45, 183, 245)' />
										Hoạt động
									</span>
								}
							/>
						)}
						{data?.status == EMPLOYEE_STATUS.Blocked && (
							<DescriptionItem
								title='Trạng thái'
								content={
									<span className='ml-2 items-center text-rose-500'>
										<Badge color='#f43f5e' />
										Ngừng hoạt động
									</span>
								}
							/>
						)}
					</Col> */}
				</Row>
			</Drawer>
		</>
	);
}
