import {Button, Col, Divider, Drawer, Form, Input, QRCode, Row} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {openNotification} from '../../Helper/notification';
import {NOTIFICATION_TYPE} from '../../Contant/global';

RevExpandItemCreate.propTypes = {
	revExpandList: PropTypes.array,
	setRevExpandList: PropTypes.func,
};
const DescriptionItem = ({title, content}) => (
	<div className='site-description-item-profile-wrapper'>
		<p className='site-description-item-profile-p-label'>{title}:</p>
		{content}
	</div>
);

DescriptionItem.propTypes = {
	title: PropTypes.string,
	content: PropTypes.any,
};

export default function RevExpandItemCreate({revExpandList, setRevExpandList}) {
	const [open, setOpen] = useState(false);
	const [form] = Form.useForm();

	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};
	const onFinish = () => {
		setRevExpandList([...revExpandList, form.getFieldsValue()]);
		form.resetFields();
		openNotification(NOTIFICATION_TYPE.success, 'Thêm thành công!');
	};
	return (
		<>
			<Button className='w-full' htmlType='button' onClick={showDrawer}>
				Thêm mới
			</Button>
			<Drawer
				width={340}
				placement='right'
				closable={false}
				onClose={onClose}
				open={open}>
				{/* <div className='flex items-center justify-between mb-6 gap-5'>
					<p className='site-description-item-profile-p font-semibold text-xl my-0'>
						<QRCode size={100} value={'-'} />
					</p>
				</div> */}
				<Form onFinish={onFinish} form={form} layout='vertical'>
					<Form.Item label='Tên' name={'name'}>
						<Input placeholder='Vui lòng nhập' className='w-full' />
					</Form.Item>
					<Form.Item label='Số lượng' name={'quanlity'}>
						<Input placeholder='Vui lòng nhập' className='w-full' />
					</Form.Item>
					<Form.Item label='Đơn vị' name={'unit'}>
						<Input placeholder='Vui lòng nhập' className='w-full' />
					</Form.Item>
					<Form.Item label='Đơn giá' name={'price'}>
						<Input placeholder='Vui lòng nhập' className='w-full' />
					</Form.Item>
					<Form.Item>
						<Button htmlType='submit' type='primary' className='w-full'>
							Thêm mới
						</Button>{' '}
					</Form.Item>
				</Form>
			</Drawer>
		</>
	);
}
