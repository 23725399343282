import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Input,
	DatePicker,
	Divider,
	Select,
	Switch,
	Form,
	Alert,
} from 'antd';
import {openNotification} from '../../Helper/notification';
import {NOTIFICATION_TYPE} from '../../Contant/global';
import {apiCreateRevExpand} from '../../API/revExpand';
import {apiGetListProject} from '../../API/project';
import RevExpandItemCreate from './RevExpandItemCreate';
import {useNavigate} from 'react-router-dom';
import {FiTrash} from 'react-icons/fi';
import {getMessageErrors, optionValidate} from '../../Helper/vailate';
const initialValues = {
	currentPage: 1,
	perPage: 1000,
};

function RevExpandCreate(props) {
	const [form] = Form.useForm();
	const [projects, setProjects] = useState([]);
	const [paramsList, setParamsList] = useState(initialValues);
	const [revExpandList, setRevExpandList] = useState([]);
	const [selectType, setSelectType] = useState(null);
	const navigate = useNavigate();
	const [errors, setErrors] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchProjectList = (params) => {
		apiGetListProject(params)
			.then((r) => {
				setProjects(r.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const onFinish = () => {
		fetchRevExpandCreate();
	};

	const fetchRevExpandCreate = () => {
		setLoading(true);
		const data = {
			bill: {
				...form.getFieldsValue(),
				supplier: form.getFieldValue('supplier') || 'Không có',
				date: new Date(form.getFieldValue('date')),
			},
			items: revExpandList,
		};
		console.log('data', data);

		apiCreateRevExpand(data)
			.then((response) => {
				openNotification(NOTIFICATION_TYPE.success, 'Tạo mới thành công !');
			})
			.then((response) => {
				navigate('/rev-expand');
			})
			.catch((err) => {
				openNotification(NOTIFICATION_TYPE.error, 'Tạo mới thất bại !');
				console.log(err);
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchProjectList(paramsList);
	}, [paramsList]);
	return (
		<Form
			onFinish={onFinish}
			onFinishFailed={() => {
				setErrors(getMessageErrors(form.getFieldsError()));
			}}
			form={form}
			layout='vertical'>
			{' '}
			<div className='p-5 ml-4 flex flex-col ' style={{width: 350}}>
				<label
					htmlFor='password'
					className='block mb-3   text-gray-900 dark:text-white mt-3'>
					Loại
				</label>
				<div>
					<Form.Item
						noStyle={true}
						validateStatus={form.getFieldError('type') ? '' : 'error'}
						rules={optionValidate(true, null, null, null, null, 'Loại')}
						label='Loại'
						name={'type'}>
						<Select
							onChange={(e) => {
								setSelectType(e);
							}}
							placeholder={'Vui lòng chọn'}
							className='w-full'
							options={[
								{
									value: 0,
									label: 'Chi ra',
								},
								{
									value: 1,
									label: 'Thu vào',
								},
							]}
						/>
					</Form.Item>
				</div>

				{(selectType == 0 || selectType == 1) && (
					<>
						<label
							htmlFor='password'
							className='block mb-3   text-gray-900 dark:text-white mt-3'>
							Tiêu đề
						</label>
						<div className=''>
							<Form.Item
								label='Tiêu đề'
								name={'title'}
								noStyle={true}
								validateStatus={form.getFieldError('title') ? '' : 'error'}
								rules={optionValidate(true, null, null, 1, 100, 'Tiêu đề')}>
								<Input placeholder={'Vui lòng nhập'} className='w-full' />
							</Form.Item>
						</div>
						<label
							htmlFor='password'
							className='block mb-3   text-gray-900 dark:text-white mt-3'>
							Tên dự án
						</label>
						<div>
							<Form.Item
								noStyle={true}
								validateStatus={form.getFieldError('projectId') ? '' : 'error'}
								rules={optionValidate(true, null, null, null, null, 'Tên dự án')}
								label='Tên dự án'
								name={'projectId'}>
								<Select
									onChange={(e) => {
										form.setFieldValue('projectId', e);
									}}
									placeholder='Công trình'
									className='w-full'
									options={projects?.map((item) => ({
										label: item?.name,
										value: item?.id,
									}))}
								/>{' '}
							</Form.Item>
						</div>
						<label
							htmlFor='password'
							className='block mb-3   text-gray-900 dark:text-white mt-3'>
							Ngày nhập
						</label>
						<div>
							<Form.Item
								noStyle={true}
								validateStatus={form.getFieldError('date') ? '' : 'error'}
								rules={optionValidate(true, null, null, null, null, 'Ngày nhập')}
								label='Ngày nhập'
								name={'date'}>
								<DatePicker className='w-full' onChange={() => {}} />
							</Form.Item>
						</div>
						{selectType == 0 && (
							<div>
								<label
									htmlFor='password'
									className='block mb-3   text-gray-900 dark:text-white mt-3'>
									Đối tác
								</label>
								<Form.Item
									noStyle={true}
									validateStatus={form.getFieldError('supplier') ? '' : 'error'}
									rules={optionValidate(true, null, null, null, null, 'Đối tác')}
									label='Đối tác'
									name={'supplier'}>
									<Input placeholder='Vui lòng nhập' className='w-full' />
								</Form.Item>
							</div>
						)}
						<div>
							<Form.Item className='mt-4' label='Chi tiết'>
								<div className='mb-4 text-sm'>
									{revExpandList?.map((item, index) => (
										<div
											key={index}
											className='flex bg-white border justify-between mb-4 p-2 px-4 rounded shadow-sm'>
											<small className='flex gap-2 items-center'>
												<FiTrash
													onClick={() => {
														const newList = _.filter(
															revExpandList,
															(item, idx) => idx != index
														);
														setRevExpandList(newList);
													}}
													className='text-rose-500'
												/>
												{item?.name}
											</small>
											<small>
												{item?.quanlity}{' '}
												<span className='text-gray-400'>( {item?.unit} )</span>
											</small>
											<small>
												{new Intl.NumberFormat().format(+item?.price * +item?.quanlity)}
											</small>
										</div>
									))}
								</div>
								<RevExpandItemCreate
									revExpandList={revExpandList}
									setRevExpandList={setRevExpandList}
								/>
							</Form.Item>
							{errors.length > 0 && (
								<Alert
									className='rounded mt-5'
									message={
										<div className='flex flex-col text-gray-500' style={{fontSize: 13}}>
											{errors?.map((o, i) => (
												<div key={i}>{o}</div>
											))}
										</div>
									}
									type='warning'
								/>
							)}
						</div>
					</>
				)}

				<div className=''>
					<Button
						loading={loading}
						className='w-full mt-4'
						type='primary'
						htmlType='submit'>
						Tạo mới
					</Button>
				</div>
			</div>
		</Form>
	);
}

RevExpandCreate.propTypes = {};

export default RevExpandCreate;
