import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import BaseInput from '../../Components/BaseInput';
import {apiEmployeeDetails, apiUpdateEmployee} from '../../API/employee';
// import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {optionValidate} from '../../Helper/vailate';
import {Divider} from 'antd';
import BaseSelectEdit from '../../Components/BaseSelectEdit';
import {apiUpdateSalary} from '../../API/salary';

function EmployeeUpdate(props) {
	const {id} = useParams();
	const [employeeDetails, setEmployeeDetails] = useState();
	// const dispatch = useDispatch();
	const getEmployeeDetails = (id) => {
		apiEmployeeDetails(id)
			.then((result) => {
				setEmployeeDetails(result);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(() => {
		if (id) {
			getEmployeeDetails(id);
		}
	}, [id]);

	return (
		<div style={{width: '100%'}}>
			<div className='w-full justify-between '>
				<div className='w-5/12 p-5 ml-5 mt-5'>
					<BaseInput
						apiUpdate={apiUpdateEmployee}
						edit={true}
						field={'fullName'}
						id={id}
						label={'Tên'}
						value={employeeDetails?.fullName}
						rules={optionValidate(true, null, null, 5, 30, 'tên nhân viên')}
					/>
					<Divider />
					<BaseInput
						apiUpdate={apiUpdateEmployee}
						edit={true}
						field={'idCard'}
						id={id}
						label={'CCCD'}
						value={employeeDetails?.idCard}
						rules={optionValidate(true, null, null, 5, 30, 'cccd')}
					/>
					<Divider />
					<BaseInput
						apiUpdate={apiUpdateEmployee}
						edit={true}
						field={'phone'}
						id={id}
						label={'Số điện thoại'}
						value={employeeDetails?.phone}
						rules={optionValidate(true, null, null, 5, 30, 'phone')}
					/>
					<Divider />
					{/* <BaseInput
					apiUpdate={apiUpdateEmployee}
					edit={true}
					field={'email'}
					id={id}
					label={'Mail'}
					value={employeeDetails?.email}
				/> */}
					{/* <Divider /> */}

					<BaseSelectEdit
						apiUpdate={apiUpdateEmployee}
						id={id}
						edit={true}
						label={'Giới tính'}
						field={'gender'}
						option={[
							{label: 'Nam', value: 0},
							{label: 'Nữ', value: 1},
						]}
						value={employeeDetails?.gender}
						rules={optionValidate(true, null, null, null, null, 'giới tính')}
					/>
					<Divider />
					<BaseInput
						apiUpdate={apiUpdateEmployee}
						edit={true}
						field={'address'}
						id={id}
						label={'Địa chỉ'}
						value={employeeDetails?.address}
						rules={optionValidate(true, null, null, 5, 100, 'Địa chỉ')}
					/>
					<Divider />
					<BaseInput
						number={true}
						apiUpdate={apiUpdateSalary}
						edit={true}
						field={'wage'}
						id={employeeDetails?.salary?.id}
						label={'Mức lương'}
						value={employeeDetails?.salary?.wage}
						rules={optionValidate(true, 10000, 10000000, null, null, 'mức lương')}
					/>
					<Divider />
					<BaseInput
						number={true}
						apiUpdate={apiUpdateSalary}
						edit={true}
						field={'wageOvertime'}
						id={employeeDetails?.salary?.id}
						label={'Tăng ca'}
						value={employeeDetails?.salary?.wageOvertime}
						rules={optionValidate(true, 10000, 10000000, null, null, 'Tăng ca')}
					/>
					{/* <Divider /> */}

					{/* <Divider />
					<BaseInput
						apiUpdate={apiUpdateEmployee}
						edit={true}
						field={'hireDate'}
						id={id}
						label={'Ngày nhận việc'}
						value={employeeDetails?.hireDate}
						rules={optionValidate('ngày nhận việc', true)}
					/> */}
					<Divider />
					<BaseSelectEdit
						apiUpdate={apiUpdateEmployee}
						id={id}
						edit={true}
						label={'Trạng thái'}
						field={'status'}
						option={[
							{label: 'Hoạt động', value: 0},
							{label: 'Ngửng hoạt động', value: 1},
						]}
						value={employeeDetails?.status}
						rules={optionValidate(true, null, null, null, null, 'trạng thái')}
					/>
				</div>
			</div>{' '}
		</div>
	);
}

EmployeeUpdate.propTypes = {};

export default EmployeeUpdate;
