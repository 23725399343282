import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import BaseInput from '../../Components/BaseInput';
import {
	apiAccountDetails,
	apiAccountMainDetails,
	apiUpdateMainAccount,
} from '../../API/account';
// import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {optionValidate} from '../../Helper/vailate';
import {Divider} from 'antd';
import BaseSelectEdit from '../../Components/BaseSelectEdit';
import {useSelector} from 'react-redux';
import {useMitt} from 'react-mitt';

function AccountDetail(props) {
	const {accountId} = useSelector((state) => state.auth);
	const [accountDetails, setAccountDetails] = useState();
	const {emitter} = useMitt();
	const getAccountDetails = (id) => {
		emitter.emit('pendingOn');

		apiAccountMainDetails(id)
			.then((result) => {
				setAccountDetails(result);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				emitter.emit('pendingOff');
			});
	};

	useEffect(() => {
		console.log('accountId', accountId);
		if (accountId) {
			getAccountDetails(accountId);
		}
	}, [accountId]);

	return (
		<div style={{width: '100%'}}>
			<div className='w-full justify-between '>
				<div className='w-5/12 p-5 ml-5 mt-5'>
					<BaseInput
						apiUpdate={apiUpdateMainAccount}
						edit={true}
						field={'company'}
						id={accountId}
						label={'Tổ chức '}
						value={accountDetails?.company}
						rules={optionValidate(true, null, null, 1, 100, 'Tổ chức')}
					/>
					<Divider />
					<BaseInput
						apiUpdate={apiUpdateMainAccount}
						edit={true}
						field={'phone'}
						id={accountId}
						label={'Số điện thoại'}
						value={accountDetails?.phone}
						rules={optionValidate(true, null, null, 10, 11, 'Số điện thoại')}
					/>
					<Divider />

					<BaseInput
						apiUpdate={apiUpdateMainAccount}
						edit={true}
						field={'email'}
						id={accountId}
						label={'Email'}
						value={accountDetails?.email}
						rules={optionValidate(true, null, null, 5, 50, 'Email')}
					/>
					<Divider />
					<BaseInput
						apiUpdate={apiUpdateMainAccount}
						edit={true}
						field={'address'}
						id={accountId}
						label={'Địa chỉ'}
						value={accountDetails?.address}
						rules={optionValidate(true, null, null, 3, 100, 'Địa chỉ')}
					/>
					<Divider />
				</div>
			</div>{' '}
		</div>
	);
}

AccountDetail.propTypes = {};

export default AccountDetail;
