import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import BaseInput from '../../Components/BaseInput';
// import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {optionValidate} from '../../Helper/vailate';
import {Divider} from 'antd';
import BaseSelectEdit from '../../Components/BaseSelectEdit';
import {apiUpdateSalary} from '../../API/salary';
import {apiEquipmentDetails, apiUpdateEquipment} from '../../API/equipment';

function EquipmentUpdate(props) {
	const {id} = useParams();
	const [equipmentDetails, setEquipmentDetails] = useState();
	// const dispatch = useDispatch();
	const getEquipmentDetails = (id) => {
		apiEquipmentDetails(id)
			.then((result) => {
				setEquipmentDetails(result);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(() => {
		if (id) {
			getEquipmentDetails(id);
		}
	}, [id]);

	return (
		<div style={{width: '100%'}}>
			<div className='w-full justify-between '>
				<div className='w-5/12 p-5 ml-5 mt-5'>
					<BaseInput
						apiUpdate={apiUpdateEquipment}
						edit={true}
						field={'name'}
						id={id}
						label={'Tên'}
						value={equipmentDetails?.name}
						rules={optionValidate(true, null, null, 5, 50, 'Tên')}
					/>
					<Divider />
					<BaseInput
						apiUpdate={apiUpdateEquipment}
						edit={true}
						field={'code'}
						id={id}
						label={'Mã vật tư'}
						value={equipmentDetails?.code}
						rules={optionValidate(true, null, null, 1, 50, 'Mã vật tư')}
					/>
					<Divider />
					<BaseInput
						apiUpdate={apiUpdateEquipment}
						edit={true}
						field={'serial'}
						id={id}
						label={'Serial'}
						value={equipmentDetails?.serial}
						rules={optionValidate(true, null, null, 1, 50, 'serial')}
					/>
					<Divider />
					{/* <BaseInput
					apiUpdate={apiUpdateEquipment}
					edit={true}
					field={'email'}
					id={id}
					label={'Mail'}
					value={equipmentDetails?.email}
				/> */}
					{/* <Divider /> */}

					<BaseInput
						apiUpdate={apiUpdateEquipment}
						edit={true}
						field={'prodcucer'}
						id={id}
						label={'Nhà cung cấp'}
						value={equipmentDetails?.prodcucer}
						rules={optionValidate(true, null, null, 3, 50, 'Nhà sản xuất')}
					/>
					<Divider />
					<BaseInput
						apiUpdate={apiUpdateEquipment}
						edit={true}
						field={'unit'}
						id={id}
						label={'Đơn vị'}
						value={equipmentDetails?.unit}
						rules={optionValidate(true, null, null, 1, 20, '	Đơn vị')}
					/>
					<Divider />
					<BaseInput
						number={true}
						apiUpdate={apiUpdateSalary}
						edit={true}
						field={'quanlity'}
						id={equipmentDetails?.quanlity}
						label={'Số lượng'}
						value={equipmentDetails?.quanlity}
						rules={optionValidate(true, 1, 100000, null, null, '	Số lượng')}
					/>
					<Divider />

					{/* <Divider /> */}

					{/* <Divider />
					<BaseInput
						apiUpdate={apiUpdateEquipment}
						edit={true}
						field={'hireDate'}
						id={id}
						label={'Ngày nhận việc'}
						value={equipmentDetails?.hireDate}
						rules={optionValidate('ngày nhận việc', true)}
					/> */}
				</div>
			</div>{' '}
		</div>
	);
}

EquipmentUpdate.propTypes = {};

export default EquipmentUpdate;
