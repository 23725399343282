import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ConsCard from '../../Components/ConsCard';
import {
	Badge,
	Button,
	Dropdown,
	Input,
	Modal,
	Pagination,
	Select,
	Space,
	Table,
} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {IoMdAdd} from 'react-icons/io';
import {apiGetListProject, apiRemoveProject} from '../../API/project';
import {useMitt} from 'react-mitt';
import {GrPowerReset} from 'react-icons/gr';
import {AiOutlineHolder} from 'react-icons/ai';
import {openNotification} from '../../Helper/notification';
import {NOTIFICATION_TYPE} from '../../Contant/global';
import ContructionDetails from './ContructionDetails';
import {ExclamationCircleOutlined} from '@ant-design/icons';

const initialValues = {
	currentPage: 1,
	perPage: 10,
	name: null,
	status: null,
};

function ConstructionList(props) {
	const navigate = useNavigate();
	const [projects, setProjects] = useState([]);
	const [paramsList, setParamsList] = useState(initialValues);
	const [modal, contextHolder] = Modal.useModal();
	const [loading, setLoading] = useState(false);
	const {emitter} = useMitt();
	const items = (data) => [
		{
			key: '1',
			label: <ContructionDetails data={data} />,
		},
		{
			key: '2',
			label: (
				<Link to={`/construction/${data?.id}/update`} rel='noopener noreferrer'>
					Cập nhật
				</Link>
			),
		},

		{
			key: '4',
			danger: true,

			label: (
				<div aria-hidden onClick={() => confirm(data?.id)} style={{width: 200}}>
					Xóa bỏ
				</div>
			),
		},
	];
	const columns = [
		{
			title: '#',

			render: (text, record, index) => (
				<span>
					{index + 1 + (paramsList?.currentPage - 1) * paramsList?.perPage}
				</span>
			),
		},
		{
			title: 'Tên dự án',
			dataIndex: 'name',
			key: 'name',
			render: (text, record) => (
				<div className='flex flex-col'>
					<span>{text}</span>
					<small className='text-gray-300'>{record?.type}</small>
				</div>
			),
		},

		{
			title: 'Chủ đầu tư',
			dataIndex: 'fullName',
			key: 'fullName',
		},
		{
			title: 'Ngân sách',
			dataIndex: 'expense',
			render: (text, record) => (
				<div className='flex flex-col'>
					<span>{new Intl.NumberFormat().format(text)}</span>
				</div>
			),
		},
		{
			title: 'Địa chỉ',
			dataIndex: 'address',
			key: 'address',
		},

		{
			title: 'Trạng thái',
			key: 'status',
			dataIndex: 'status',
			className: 'text-center',
			render: (text) => {
				return (
					<>
						{text == 0 && (
							<div
								className='rounded-sm text-center text-gray-500 '
								style={{width: 120}}>
								Chuẩn bị
							</div>
						)}
						{text == 1 && (
							<div
								className='rounded-sm text-center text-blue-500 '
								style={{width: 120}}>
								Hoạt động
							</div>
						)}
						{text == 2 && (
							<div
								className='rounded-sm text-center text-green-500 '
								style={{width: 120}}>
								Hoàn thành
							</div>
						)}
						{text == 3 && (
							<div
								className='rounded-sm text-center text-red-500 '
								style={{width: 120}}>
								Hủy bỏ
							</div>
						)}
					</>
				);
			},
		},
		{
			title: 'Ngày tạo',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (_, record) => (
				<>
					<div>{moment(record?.createdAt).format('hh:mm DD/MM/YYYY')}</div>
					<small className='text-gray-300'>
						{moment(record?.updatedAt).format('hh:mm DD/MM/YYYY')}
					</small>
				</>
			),
		},
		{
			title: 'Thao tác',
			key: 'action',

			render: (_, record) => (
				<Dropdown
					className='w-48'
					menu={{
						items: items(record),
					}}>
					<span
						aria-hidden
						className='text-sky-600 '
						onClick={(e) => e.preventDefault()}>
						<Space>
							<AiOutlineHolder />
						</Space>
					</span>
				</Dropdown>
			),
		},
	];

	const fetchRemoveProject = (id) => {
		() => {
			apiRemoveProject(id)
				.then((response) => {
					openNotification(NOTIFICATION_TYPE.success, 'Xóa thành công !');
				})
				.then((response) => {
					navigate('/construction');
				})
				.catch((err) => {
					openNotification(NOTIFICATION_TYPE.error, 'Xóa thất bại !');
					console.log(err);
				});
		};
	};
	const confirm = (id) => {
		modal.confirm({
			title: 'Xác nhận',
			icon: <ExclamationCircleOutlined />,
			content: 'Bạn chắc chắn muốn xóa !',
			okText: 'Đồng ý',
			onOk: () => {
				console.log('sadfads');
				fetchRemoveProject(id);
			},
		});
	};
	const fetchProjectList = (params) => {
		emitter.emit('pendingOn');

		apiGetListProject(params)
			.then((r) => {
				setProjects(r);
			})
			.catch((e) => {
				console.log(e, projects?.data?.length > 0);
			})
			.finally(() => {
				emitter.emit('pendingOff');
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchProjectList(paramsList);
	}, []);
	return (
		<div>
			{contextHolder}

			<div className='flex justify-between mt-7 px-7'>
				<div className=''>
					<Button
						className='flex items-center gap-2'
						onClick={() => {
							navigate('/construction/create');
						}}
						type='primary'>
						Tạo mới <IoMdAdd />
					</Button>
				</div>
				{/* <div className='flex justify-center items-center gap-4'>
					<Badge className='' text={'Chuẩn bị'} color='gray' />
					<Badge className='' text={'Hoạt động'} color='blue' />
					<Badge className='' text={'Hoàn thành'} color='green' />
				</div> */}
				<div className='flex justify-end gap-2'>
					<Select
						value={paramsList?.status}
						placeholder={'Trạng thái'}
						onChange={(e) => setParamsList({...paramsList, status: e})}
						style={{
							width: 150,
						}}
						options={[
							{
								value: 0,
								label: 'Chuẩn bị',
							},
							{
								value: 1,
								label: 'Hoạt động',
							},
							{
								value: 2,
								label: 'Hoàn thành',
							},
							{
								value: 3,
								label: 'Hủy bỏ',
							},
						]}
					/>
					<Input
						style={{
							width: 150,
						}}
						value={paramsList?.name}
						onChange={(e) => setParamsList({...paramsList, name: e.target.value})}
						placeholder='Tên dự án'
					/>
					<Button
						onClick={() => {
							setParamsList(initialValues);
						}}
						className=''
						type='default'>
						<GrPowerReset />
					</Button>
					<Button
						loading={loading}
						onClick={() => {
							setLoading(true);
							fetchProjectList(paramsList);
						}}
						className=''
						type='primary'>
						Tìm kiếm
					</Button>
				</div>
			</div>
			{/* {projects?.data?.length == 0 || (
				<div className='flex w-full h-80 justify-center items-center'>
					<div className='font-bold text-5xl text-gray-300 opacity-15 '>EMPTY</div>
				</div>
			)} */}
			<div className='mt-5'>
				<Table columns={columns} dataSource={projects?.data} pagination={false} />
			</div>
			<div className='flex justify-end px-10 mt-5'>
				<Pagination
					pageSize={paramsList.perPage}
					current={paramsList?.currentPage}
					total={projects?.meta?.total}
					onChange={(v) => {
						setParamsList({...initialValues, currentPage: v});
						fetchProjectList({...initialValues, currentPage: v});
					}}
				/>
			</div>
		</div>
	);
}

ConstructionList.propTypes = {};

export default ConstructionList;
