import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import BaseInput from '../../Components/BaseInput';
import {
	apiRevExpandItemDetails,
	apiUpdateRevExpandItem,
} from '../../API/revExpandItem';
// import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {optionValidate} from '../../Helper/vailate';
import {Button, Divider, Input} from 'antd';
import BaseSelectEdit from '../../Components/BaseSelectEdit';
import {MdModeEditOutline} from 'react-icons/md';

function RevExpandItemDetailUpdate(props) {
	const {id} = useParams();
	const [revExpandDetails, setRevExpandItemDetails] = useState();
	// const dispatch = useDispatch();
	const getRevExpandItemDetails = (id) => {
		apiRevExpandItemDetails(id)
			.then((result) => {
				setRevExpandItemDetails(result);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(() => {
		if (id) {
			getRevExpandItemDetails(id);
		}
	}, [id]);

	return (
		<div style={{width: '100%'}}>
			<div className='w-full justify-between '>
				<div className='w-5/12 p-5 ml-5 mt-5'>
					<BaseInput
						apiUpdate={apiUpdateRevExpandItem}
						edit={true}
						field={'name'}
						id={id}
						label={'Tên'}
						value={revExpandDetails?.name}
						rules={optionValidate(true, null, null, 1, 100, 'Tên')}
					/>
					<Divider />
					<BaseInput
						apiUpdate={apiUpdateRevExpandItem}
						edit={true}
						field={'quanlity'}
						number={true}
						id={id}
						label={'Số lượng'}
						value={revExpandDetails?.quanlity}
						rules={optionValidate(true, 1, 100000, null, null, 'Số lượng')}
					/>
					<Divider />

					<BaseInput
						apiUpdate={apiUpdateRevExpandItem}
						edit={true}
						field={'unit'}
						id={id}
						label={'Đơn vị'}
						value={revExpandDetails?.unit}
						rules={optionValidate(true, null, null, 1, 50, 'Đơn vị')}
					/>
					<Divider />

					<BaseInput
						apiUpdate={apiUpdateRevExpandItem}
						edit={true}
						field={'price'}
						id={id}
						label={'Giá'}
						number={true}
						value={revExpandDetails?.price}
						rules={optionValidate(true, 1, 1000000000, null, null, 'Giá')}
					/>

					{revExpandDetails?.revexpanditem?.map((item, index) => (
						<>
							<Divider />
							<div className='grid grid-cols-12 items-center'>
								<div className='col-span-3'>{index + 1} .</div>
								<div className='col-span-6'>
									<Input value={item?.name} />
								</div>
								<div className='col-span-3 flex justify-end'>
									<Button type='link'>
										<MdModeEditOutline />
									</Button>
								</div>
							</div>
						</>
					))}
				</div>
			</div>{' '}
		</div>
	);
}

RevExpandItemDetailUpdate.propTypes = {};

export default RevExpandItemDetailUpdate;
