import {axios} from '../Conf/axios';

export const apiGetListArise = (params) => axios.get('/arise/list', {params});

export const apiCreateArise = (data) => axios.post(`/arise/create`, data);

export const apiUpdateArise = (id, data) =>
	axios.put(`/arise/${id}/update`, data);

export const apiRemoveArise = (id) => axios.delete(`/arise/${id}/remove`);

export const apiAriseDetails = (id) => axios.get(`/arise/${id}/details`);
