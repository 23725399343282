import {axios} from '../Conf/axios';

export const apiGetListRevExpandItem = (params) =>
	axios.get('/rev-expand-item/list', {params});

export const apiCreateRevExpandItem = (data) =>
	axios.post(`/rev-expand-item/create`, data);

export const apiUpdateRevExpandItem = (id, data) =>
	axios.put(`/rev-expand-item/${id}/update`, data);

export const apiRemoveRevExpandItem = (id) =>
	axios.delete(`/rev-expand-item/${id}/remove`);

export const apiRevExpandItemDetails = (id) =>
	axios.get(`/rev-expand-item/${id}/details`);

export const apiGetRevExpandTotal = (params) =>
	axios.get('/rev-expand-item/by-month', {params});

export const apiGetRevExpandProject = (id) =>
	axios.get(`/rev-expand-item/${id}/project-total`);
